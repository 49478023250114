<template>
  <div class="login">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
    >
      <div class="title1">欢迎登录</div>
      <div class="title2">颐倍康评估管理系统</div>
      <el-form-item prop="phone">
        <el-input
          v-model="loginForm.phone"
          type="text"
          auto-complete="off"
          placeholder="请输入手机号"
        >
          <i slot="prefix" class="el-input__icon">
            <img
              style="width: 18px; height: 18px; padding: 2px 2px 2px 5px"
              src="../../static/images/username.png"
              alt
            />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item prop="codeimg">
        <el-input
          v-model="loginForm.codeimg"
          auto-complete="off"
          placeholder="请输入图片验证码"
          style="width: 60%"
        >
          <i slot="prefix" class="el-input__icon">
            <img
              style="width: 18px; height: 18px; padding: 2px 2px 2px 5px"
              src="../../static/images/dunpai.png"
              alt
            />
          </i>
        </el-input>
        <div class="login-code2">
          <img :src="codeUrl" @click="CodeImg" class="login-code-img" />
        </div>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="请输入手机验证码"
          style="width: 60%"
          maxlength="6"
          @keyup.enter.native="handleLogin"
        >
          <i slot="prefix" class="el-input__icon">
            <img
              style="width: 18px; height: 18px; padding: 2px 2px 2px 5px"
              src="../../static/images/password.png"
              alt
            />
          </i>
        </el-input>
        <div class="login-code">
          <div class="login-code-img" @click="getCode" v-if="issecond == false">
            获取验证码
          </div>
          <div class="login-code-img" v-if="issecond == true">
            {{ second + "s后重新获取" }}
          </div>
        </div>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 100%"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <!-- <div class="el-login-footer">
      <span>Copyright © 2018-2021 ruoyi.vip All Rights Reserved.</span>
    </div> -->
  </div>
</template>

<script>
import { login, sendCode, getCodeImg } from "@/api/login/login";
export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      // 验证码时间
      second: 60,
      issecond: false,
      loginForm: {
        phone: "",
        codeimg: "",
        code: "",
        uuid: "",
        UID: "",
      },
      loginRules: {
        phone: [
          { required: true, trigger: "blur", message: "手机号不能为空" },

          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
            required: true,
          },
        ],
        code: [{ required: true, trigger: "blur", message: "验证码不能为空" }],
        codeimg: [
          { required: true, trigger: "blur", message: "验证码不能为空" },
        ],
      },
      loading: false,
      redirect: "",
      randnum: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    this.CodeImg();
  },
  methods: {
    //发送验证码
    getCode() {
      if (!this.loginForm.phone) {
        this.$message({
          message: "手机号不能为空",
          type: "warning",
        });
        return false;
      }
      if (!this.loginForm.codeimg) {
        this.$message({
          message: "验证码不能为空",
          type: "warning",
        });
        return false;
      }

      let data = new FormData();
      data.append("phone", this.loginForm.phone);
      data.append("uuid", this.loginForm.uuid);
      data.append("code", this.loginForm.codeimg);
      sendCode(data)
        .then((result) => {
          console.log(result);
          if (result.code == 200) {
            if (result.data.code == "OK") {
              this.issecond = true;
              this.UID = result.msg;
              this.downtimer();
            } else {
              this.$message({
                message: result.data.message,
                type: "warning",
              });
            }
          } else {
            this.CodeImg();
          }
        })
        .catch((err) => {
          this.issecond == false;
        });
      // });
    },
    // 安全码
    CodeImg() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    // 定时器
    downtimer() {
      let that = this;
      let timer = setInterval(function () {
        that.second--;
        if (that.second <= 0) {
          that.issecond = false;
          that.second = 60;
          clearInterval(timer);
        }
      }, 1000);
    },
    // 登录
    handleLogin() {
      /* 走的接口在store的user.js中 */
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = new FormData();
          data.append("userName", this.loginForm.phone);
          data.append("phoneCode", this.loginForm.code);
          data.append("password", this.loginForm.phone);
          data.append("UUID", this.UID);
          login(data)
            .then((result) => {
              localStorage.setItem("Token", result.ybk_token);
              localStorage.setItem("userId", result.data.userId);
              localStorage.setItem("userName", result.data.userName);
              this.$router.push({
                path: "/home",
              });
            })
            .catch((err) => {
              this.loading = false;
              console.log("err", err);
            });
        }
      });
    },
  },
};
</script>
<style  scoped>
div /deep/.el-input__inner {
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  border: 2px solid #cbe7fe;
}
div /deep/.el-form-item {
  margin-bottom: 3vh;
}
div /deep/ .el-button--medium {
  padding: 18px 20px;
  border-radius: 4px;
  font-size: 1.2vw;
}
div /deep/ .el-input {
  font-size: 1vw;
}
div /deep/.el-input__icon {
  line-height: 60px;
}
</style>
<style rel="stylesheet/less" lang="less" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../../static/images/loginback.jpg");
  background-size: cover;
}
.title {
  // margin: 0px auto 30px auto;
  text-align: center;
  color: #1e90ec;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 18vw;
  margin-left: 45vw;
  padding: 1vw 4vw;
  border-radius: 1vw;
  .el-input {
    height: 38px;
    input {
      height: 38px;
      border-radius: 10px;
    }
  }
  .title1 {
    color: #1e90ec;
    font-size: 1.7vw;
    padding-top: 0;
    padding: 1vh 0;
  }
  .title2 {
    color: #1e90ec;
    font-size: 1vw;
    padding: 1vh 0;
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 38%;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  background: #6abaff;
  color: #fff;
  float: right;
  font-size: 0.8vw;
  cursor: pointer;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.login-code2 {
  width: 38%;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  color: #fff;
  float: right;
  font-size: 0.8vw;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  width: 100%;
  // height: 38px;
  height: 100%;
}
</style>
