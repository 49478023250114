<template>
  <div class="">
    <!-- <el-button @click="getPdf()" type="primary">生成PDF</el-button> -->
    <div class="top">
      <div class="homepage" @click="go_officialwebsite">首页</div>
      <el-dropdown>
        <img src="../../static/images/headerlogo.png" alt="" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-form
      ref="elForm"
      :model="formData"
      size="medium"
      disabled
      label-width="150px"
      label-position="left"
      class="box"
    >
      <div class="row" ref="pdfDom" id="pdfDom" style="background-color: #fff">
        <el-col :span="24">
          <h1>老人能力评估报告</h1>
        </el-col>
        <el-row class="lable">
          <el-col :span="12"> 评估对象姓名:</el-col>
          <el-col :span="12"> {{ formData.name }}</el-col>
        </el-row>
        <el-row class="lable">
          <el-col :span="12"> 评估编号:</el-col>
          <el-col :span="12">{{ formData.number }}</el-col>
        </el-row>
        <el-row class="lable">
          <el-col :span="12"> 评估原因:</el-col>
          <el-col :span="12" v-if="formData.evaluatecause == 0"
            >接受服务前初评</el-col
          >
          <el-col :span="12" v-if="formData.evaluatecause == 1"
            >接受服务后的常规评估</el-col
          >
          <el-col :span="12" v-if="formData.evaluatecause == 2"
            >状况发生变化后的即时评估</el-col
          >
          <el-col :span="12" v-if="formData.evaluatecause == 3"
            >因评估结果有疑问进行的复评</el-col
          >
        </el-row>
        <el-row class="lable">
          <el-col :span="12"> 评估机构:</el-col>
          <el-col :span="12">颐倍康</el-col>
        </el-row>
        <el-row class="lable">
          <el-col :span="12"> 评估日期:</el-col>
          <el-col :span="12">{{ formData.evaluationDate }}</el-col>
        </el-row>
        <el-col :span="24">
          <h2>老人能力评估基本信息表</h2>
        </el-col>
        <el-col :span="24">
          <div class="title">基本信息表</div>
        </el-col>
        <el-form-item label="评估编号">
          <div class="">{{ formData.number }}</div>
        </el-form-item>
        <el-form-item label="评估基准日期">
          <div class="">{{ formData.evaluationDate }}</div>
        </el-form-item>
        <el-form-item label="姓名">
          <div class="">{{ formData.name }}</div>
        </el-form-item>
        <el-form-item label="性别">
          <div class="">{{ formData.gender == 0 ? "男" : "女" }}</div>
        </el-form-item>
        <el-form-item label="出生日期">
          <div class="">{{ formData.birthDate }}</div>
        </el-form-item>
        <el-form-item label="身份证号">
          <div class="">{{ formData.card }}</div>
        </el-form-item>
        <el-form-item label="所在区划">
          <div class="">{{ formData.areaName }}</div>
        </el-form-item>
        <el-form-item label="详细地址">
          <div class="">{{ formData.fulladdress }}</div>
        </el-form-item>
        <el-form-item label="移动电话">
          <div class="">{{ formData.phone2 }}</div>
        </el-form-item>
        <el-form-item label="固话">
          <div class="">{{ formData.phone1 }}</div>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="评估原因">
          <el-radio-group
            v-model="formData.evaluatecause"
            size="medium"
            disabled
          >
            <el-radio
              v-for="(item, index) in evaluatecauseOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="民族">
          <el-radio-group v-model="formData.nation" size="medium" disabled>
            <el-radio
              v-for="(item, index) in nationOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="文化程度">
          <el-radio-group v-model="formData.education" size="medium" disabled>
            <el-radio
              v-for="(item, index) in educationOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="婚姻状况">
          <el-radio-group v-model="formData.marriage" size="medium" disabled>
            <el-radio
              v-for="(item, index) in marriageOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="居住状况">
          <el-radio-group v-model="formData.live" size="medium" disabled>
            <el-radio
              v-for="(item, index) in liveOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="经济来源">
          <el-checkbox-group
            v-model="formData.moneySource"
            size="medium"
            disabled
          >
            <el-checkbox
              v-for="(item, index) in moneySourceOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="疾病诊断-痴呆">
          <el-radio-group v-model="formData.dementia" size="medium" disabled>
            <el-radio
              v-for="(item, index) in dementiaOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="疾病诊断-精神疾病">
          <el-radio-group v-model="formData.spirit" size="medium" disabled>
            <el-radio
              v-for="(item, index) in spiritOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="疾病诊断-慢性疾病">
          <el-input
            v-model="formData.chronic"
            placeholder="请输入疾病诊断-慢性疾病"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="近30天内意外事件-跌倒">
          <el-radio-group v-model="formData.fall" size="medium" disabled>
            <el-radio
              v-for="(item, index) in fallOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="近30天内意外事件-走失">
          <el-radio-group v-model="formData.lost" size="medium" disabled>
            <el-radio
              v-for="(item, index) in lostOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="近30天内意外事件-噎食">
          <el-radio-group v-model="formData.blocking" size="medium" disabled>
            <el-radio
              v-for="(item, index) in blockingOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="近30天内意外事件-自杀">
          <el-radio-group v-model="formData.suicide" size="medium" disabled>
            <el-radio
              v-for="(item, index) in suicideOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="近30天内意外事件-其他" prop="other">
          <el-input
            v-model="formData.other"
            placeholder="请输入近30天内意外事件-其他"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="信息提供者的姓名">
          <el-input
            v-model="formData.provideName"
            placeholder="请输入信息提供者的姓名"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="信息提供者与老人的关系">
          <el-radio-group
            v-model="formData.provideOldman"
            size="medium"
            disabled
          >
            <el-radio
              v-for="(item, index) in provideOldmanOptions"
              :key="index"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-divider></el-divider>
        <!-- 老人能力评估表 -->
        <el-col :span="24">
          <h2>老人能力评估表</h2>
        </el-col>
        <div v-for="(item, index) in list" :key="index">
          <el-col :span="24">
            <div class="title">{{ item.title }}</div>
          </el-col>
          <div>
            <div v-for="(item1, index1) in item.children" :key="index1">
              <div class="pitchtitel">{{ item1.name }}</div>
              <div
                v-for="(item2, index2) in item1.children"
                :key="index2"
                :class="index2 == item1.value ? 'pitchon' : 'pitchoff'"
                @click="changepitch(index, index1)"
              >
                {{ item2.name }}
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <div class="totalpoints">
            <div class="totaltitle">{{ item.totaltitle }}</div>
            <div>{{ item.total }}分</div>
            <div class="totalgrade">{{ item.totalgrade }}</div>
          </div>
          <div class="totalpoints">
            <div class="totaltitle">{{ item.totalrank }}</div>
            <div>{{ item.rank }}级</div>
            <div class="gradeBox" v-if="index != 2">
              <div
                class="totalgrade"
                v-for="(item3, index3) in item.grade"
                :key="index3"
              >
                {{ item3 }}
              </div>
            </div>

            <div class="gradeBox" v-else>
              <div
                class="totalgrade2"
                v-for="(item3, index3) in item.grade"
                :key="index3"
              >
                {{ item3 }}
              </div>
            </div>
          </div>
        </div>
        <!-- 老年人能力评估报告 -->
        <!-- 老年人能力评估报告 -->
        <div class="pdfpage">
          <div class="title">老年人能力评估报告</div>
          <!--  
          :span-method="objectSpanMethod2"-->
          <section>
            <el-table
              :show-header="false"
              :data="tableReport"
              border
              :span-method="objectSpan"
              style="width: 100%; margin: 0 auto"
            >
              <el-table-column>
                　<template slot-scope="scope">
                  <div v-if="scope.row.id == 7">
                    <div>注：老年人能力初步等级划分标准</div>
                    <div>0 能力完好：</div>
                    <div>
                      日常生活活动、精神状态、感知觉与沟通分级均为
                      0，社会参与分级为 0 或 1
                    </div>
                    <div>1 轻度失能：</div>
                    <div>
                      日常生活活动分级为
                      0，但精神状态、感知觉与沟通中至少一项分级为 1
                      及以上，或社会参与的分级为 2；
                    </div>
                    <div>
                      或日常生活活动分级为
                      1，精神状态、感知觉与沟通、社会参与中至少有一项的分级为 0
                      或 1
                    </div>
                    <div>2 中度失能：</div>
                    <div>
                      日常生活活动分级为
                      1，但精神状态、感知觉与沟通、社会参与均为 2，或有一项为
                      3；
                    </div>
                    <div>
                      或日常生活活动分级为
                      2，且精神状态、感知觉与沟通、社会参与中有 1-2 项的分级为 1
                      或 2
                    </div>
                    <div>3 重度失能：</div>
                    <div>
                      日常生活活动的分级为 3；
                      或日常生活活动、精神状态、感知觉与沟通、社会参与分级均为
                      2；
                    </div>
                    <div>
                      或日常生活活动分级为
                      2，且精神状态、感知觉与沟通、社会参与中至少有一项分级为3
                    </div>
                  </div>
                  <div v-else>{{ scope.row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column>
                　<template slot-scope="scope">
                  <div v-if="scope.row.id == 2">
                    <el-radio
                      disabled
                      v-model="evaluation.firstResult"
                      label="0"
                      >能力完好</el-radio
                    >
                    <el-radio
                      disabled
                      v-model="evaluation.firstResult"
                      label="1"
                      >轻度失能</el-radio
                    >
                    <el-radio
                      disabled
                      v-model="evaluation.firstResult"
                      label="2"
                      >中度失能</el-radio
                    >
                    <el-radio
                      disabled
                      v-model="evaluation.firstResult"
                      label="3"
                      >重度失能</el-radio
                    >
                  </div>
                  <div v-if="scope.row.id == 3">
                    <el-radio disabled v-model="evaluation.levelUp" label="0"
                      >有认知障碍/痴呆、精神疾病者，在原有能力级别上提高一个等级；</el-radio
                    ><br />
                    <el-radio disabled v-model="evaluation.levelUp" label="1"
                      >近 30 天内发生过 2
                      次及以上跌倒、噎食、自杀、走失者，在原有能力级别上提高一
                      个等级；</el-radio
                    ><br />
                    <el-radio disabled v-model="evaluation.levelUp" label="2"
                      >处于昏迷状态者，直接评定为重度失能；</el-radio
                    ><br />
                    <el-radio disabled v-model="evaluation.levelUp" label="3"
                      >若初步等级确定为“3 重度失能”，则不考虑上述 1-3
                      中各情况对最终等级的影响， 等级不再提高</el-radio
                    ><br />
                  </div>
                  <div v-if="scope.row.id == 4">
                    <el-radio
                      disabled
                      v-model="evaluation.finalResult"
                      label="0"
                      >能力完好</el-radio
                    >
                    <el-radio
                      disabled
                      v-model="evaluation.finalResult"
                      label="1"
                      >轻度失能</el-radio
                    >
                    <el-radio
                      disabled
                      v-model="evaluation.finalResult"
                      label="2"
                      >中度失能</el-radio
                    >
                    <el-radio
                      disabled
                      v-model="evaluation.finalResult"
                      label="3"
                      >重度失能</el-radio
                    >
                  </div>

                  <div v-if="scope.row.id == 1">{{ scope.row.amount1 }}</div>
                  　</template
                >
              </el-table-column>
              <el-table-column width="500">
                　<template slot-scope="scope">
                  <div
                    class="assessor"
                    v-if="scope.row.id == 5 || scope.row.id == 6"
                  >
                    日期:{{ evaluation.assessorDate }}
                  </div>
                  <div v-else>{{ scope.row.amount2 }}</div>
                </template>
              </el-table-column>
            </el-table>
          </section>
        </div>
        <div class="pdfpage">
          <!-- 老年人能力（初步等级）评估结果判定卡 -->
          <el-table
            style="width: 100%; margin: 0 auto; margin-top: 10px; color: black"
            :data="tableData1"
            :cell-style="columnStyle"
            :span-method="objectSpanMethod"
          >
            <el-table-column label="初步能力等级" align="center" width="150">
              　<template slot-scope="scope">
                <div v-if="scope.row.id == 5" class="content">
                  <div>
                    注:使用结果判定卡时,一般根据日常生活活动进行初步定位,所动目标区域,然后根据其他三项能力
                    ,在判定卡上同一颜色区域定位检查相应的能力等级。以下为几种特殊情况
                    :
                  </div>
                  <div>
                    1.当日常生活活动为0,精神状态、感知觉与沟通有一项为1及以上,或社会参与为2,判定为轻度失能
                    ;
                  </div>
                  <div>
                    2.当日常生活活动为1,后三项有一项为0或1,判定为轻度失能
                    ;后三项均为2或某一项为3,则判定为中度失能 ;
                  </div>
                  <div>
                    3.当日常生活活动为2,后三项全部为2或某一项为3,则判定为重度失能，否则为中度失能。
                  </div>
                </div>
                <div v-else v-html="scope.row.level"></div>
                　</template
              >
            </el-table-column>
            <el-table-column align="center" label="日常生活活动" width="150">
              <template slot-scope="scope">
                <span v-html="scope.row.dayActive"></span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="精神状态" width="100">
              <template slot-scope="scope">
                <span v-html="scope.row.province"></span>
              </template>
              <el-table-column align="center" label="0">
                <template slot-scope="scope">
                  <span v-html="scope.row.spiritAmount0"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="1">
                <template slot-scope="scope">
                  <span v-html="scope.row.spiritAmount1"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="2">
                <template slot-scope="scope">
                  <span v-html="scope.row.spiritAmount2"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="3">
                <template slot-scope="scope">
                  <span v-html="scope.row.spiritAmount3"></span>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column align="center" label="感知觉与沟通" width="100">
              <template slot-scope="scope">
                <span v-html="scope.row.province"></span>
              </template>
              <el-table-column align="center" label="0">
                <template slot-scope="scope">
                  <span v-html="scope.row.perceptionAmount0"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="1">
                <template slot-scope="scope">
                  <span v-html="scope.row.perceptionAmount1"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="2">
                <template slot-scope="scope">
                  <span v-html="scope.row.perceptionAmount2"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="3">
                <template slot-scope="scope">
                  <span v-html="scope.row.perceptionAmount3"></span>
                </template>
              </el-table-column>
            </el-table-column>

            <el-table-column align="center" label="社会参与" width="150">
              <el-table-column align="center" label="0">
                <template slot-scope="scope">
                  <span v-html="scope.row.socialAmount0"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="1">
                <template slot-scope="scope">
                  <span v-html="scope.row.socialAmount1"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="2">
                <template slot-scope="scope">
                  <span v-html="scope.row.socialAmount2"></span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="3">
                <template slot-scope="scope">
                  <span v-html="scope.row.socialAmount3"></span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import richang from "../js/report.js";
import { detail } from "@/api/login/login";
import {
  evaluatecauseOptions,
  nationOptions,
  educationOptions,
  marriageOptions,
  liveOptions,
  moneySourceOptions,
  dementiaOptions,
  spiritOptions,
  fallOptions,
  lostOptions,
  blockingOptions,
  provideOldmanOptions,
  suicideOptions,
} from "@/utils/auth";
export default {
  components: {},
  props: {},
  data() {
    return {
      htmlTitle: "老人能力评估报告",
      tableData1: [
        {
          id: 1,
          level: "0 能力完好",
          dayActive: 0,
          spiritAmount0: "",
          perceptionAmount0: "",
          socialAmount0: "",
          spiritAmount1: "",
          perceptionAmount1: "",
          socialAmount1: "",
          spiritAmount2: "",
          perceptionAmount2: "",
          socialAmount2: "",
          spiritAmount3: "",
          perceptionAmount3: "",
          socialAmount3: "",
        },
        {
          id: 1,
          level: "0 能力完好",
          dayActive: 1,
        },
        {
          id: 1,
          level: "0 能力完好",
          dayActive: 2,
        },
        {
          id: 1,
          level: "0 能力完好",
          dayActive: 3,
        },
        {
          id: 2,
          level: "1 轻度失能",
          dayActive: 0,
        },
        {
          id: 2,
          level: "1 轻度失能",
          dayActive: 1,
        },
        {
          id: 2,
          level: "1 轻度失能",
          dayActive: 2,
        },
        {
          id: 2,
          level: "1 轻度失能",
          dayActive: 3,
        },
        {
          id: 3,
          level: "2 中度失能",
          dayActive: 0,
        },
        {
          id: 3,
          level: "2 中度失能",
          dayActive: 1,
        },
        {
          id: 3,
          level: "2 中度失能",
          dayActive: 2,
        },
        {
          id: 3,
          level: "2 中度失能",
          dayActive: 3,
        },
        {
          id: 4,
          level: "3 重度失能",
          dayActive: 0,
        },
        {
          id: 4,
          level: "3 重度失能",
          dayActive: 1,
        },
        {
          id: 4,
          level: "3 重度失能",
          dayActive: 2,
        },
        {
          id: 4,
          level: "3 重度失能",
          dayActive: 3,
        },
        {
          id: 5,
        },
      ],
      tableReport: [
        {
          id: "1",
          name: "一级指标分级",
          amount1: "",
          amount2: "",
        },
        {
          id: "1",
          name: "一级指标分级",
          amount1: "",
          amount2: "",
        },
        {
          id: "2",
          name: "老年人能力初步等级",
        },
        {
          id: "3",
          name: "等级变更条款",
        },
        {
          id: "4",
          name: "老年人能力最终等级",
        },
        {
          id: "7",
          name: "",
        },
      ],
      // 评估报告数据
      evaluation: {
        firstResult: "",
        levelUp: "",
        finalResult: "",
        assessor1: "",
        assessorImg1: "",
        assessorDate: "",
        signature: "",
        signatureImg: "",
        signatureDate: "",
      },
      formData: {},
      list: richang,
      // 评估原因
      evaluatecauseOptions: "",
      nationOptions: "",
      // 文化程度
      educationOptions: "",
      marriageOptions: "",
      liveOptions: "",
      moneySourceOptions: "",
      dementiaOptions: "",
      spiritOptions: "",
      fallOptions: "",
      lostOptions: "",
      blockingOptions: "",
      suicideOptions: "",
      provideOldmanOptions: "",
      elderid: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.elderid = this.$route.query.id;
    // 给页面数据赋值
    this.evaluatecauseOptions = evaluatecauseOptions();
    this.nationOptions = nationOptions();
    this.educationOptions = educationOptions();
    this.marriageOptions = marriageOptions();
    this.liveOptions = liveOptions();
    this.moneySourceOptions = moneySourceOptions();
    this.dementiaOptions = dementiaOptions();
    this.spiritOptions = spiritOptions();
    this.fallOptions = fallOptions();
    this.lostOptions = lostOptions();
    this.blockingOptions = blockingOptions();
    this.suicideOptions = suicideOptions();
    this.provideOldmanOptions = provideOldmanOptions();
  },
  mounted() {
    this.get_detail();
  },
  methods: {
    go_officialwebsite() {
      this.$router.push({
        name: "officialwebsite",
      });
      console.log("this.routrt", this.$route);
    },
    // 获取详情信息
    get_detail() {
      let that = this;
      let data = new FormData();
      data.append("id", this.$route.query.id);
      detail(data)
        .then((result) => {
          that.formData = result.data;
          that.formData.provideOldman = that.formData.provideOldman * 1;
          if (that.formData.moneySource.length > 1) {
          }
          let arr = that.formData.moneySource.split(",");
          for (let i in arr) {
            arr[i] = arr[i] * 1;
          }
          that.formData.moneySource = arr;
          // 日常生活
          that.list[0].total = that.formData.params.dailyActivity.score;
          that.list[0].rank = that.formData.params.dailyActivity.level;
          that.list[0].children[0].value =
            that.formData.params.dailyActivity.eat;
          that.list[0].children[1].value =
            that.formData.params.dailyActivity.bathe;
          that.list[0].children[2].value =
            that.formData.params.dailyActivity.beautify;
          that.list[0].children[3].value =
            that.formData.params.dailyActivity.dress;
          that.list[0].children[4].value =
            that.formData.params.dailyActivity.stoolControl;
          that.list[0].children[5].value =
            that.formData.params.dailyActivity.urinationControl;
          that.list[0].children[6].value =
            that.formData.params.dailyActivity.toilet;
          that.list[0].children[7].value =
            that.formData.params.dailyActivity.bedChairTransfer;
          that.list[0].children[8].value =
            that.formData.params.dailyActivity.levelWalking;
          that.list[0].children[9].value =
            that.formData.params.dailyActivity.stairActivity;

          // 精神
          that.list[1].total = that.formData.params.mental.score;
          that.list[1].rank = that.formData.params.mental.level;
          that.list[1].children[0].value =
            that.formData.params.mental.cognitive;
          that.list[1].children[1].value =
            that.formData.params.mental.aggressiveBehavior;
          that.list[1].children[2].value =
            that.formData.params.mental.depressiveSymptoms;
          // 感知
          that.list[2].total =
            that.formData.params.perceptionCommunication.score;
          that.list[2].rank =
            that.formData.params.perceptionCommunication.level;
          that.list[2].children[0].value =
            that.formData.params.perceptionCommunication.consciousnessLevel;
          that.list[2].children[1].value =
            that.formData.params.perceptionCommunication.vision;
          that.list[2].children[2].value =
            that.formData.params.perceptionCommunication.hearing;
          that.list[2].children[3].value =
            that.formData.params.perceptionCommunication.communication;

          // 社会

          that.list[3].total = that.formData.params.socialParticipation.score;
          that.list[3].rank = that.formData.params.socialParticipation.level;
          that.list[3].children[0].value =
            that.formData.params.socialParticipation.lifeAbility;
          that.list[3].children[1].value =
            that.formData.params.socialParticipation.workAbility;
          that.list[3].children[2].value =
            that.formData.params.socialParticipation.timeSpace;
          that.list[3].children[3].value =
            that.formData.params.socialParticipation.personalOrientation;
          that.list[3].children[4].value =
            that.formData.params.socialParticipation.societyAbility;

          that.evaluation.firstResult =
            that.formData.params.evaluation.firstResult + "";
          that.evaluation.levelUp =
            that.formData.params.evaluation.levelUp + "";
          that.evaluation.finalResult =
            that.formData.params.evaluation.finalResult + "";
          that.tableReport[0].amount1 =
            "日常生活活动：" + that.formData.params.dailyActivity.level + "级";
          that.tableReport[0].amount2 =
            "精神状态：" + that.formData.params.mental.level + "级";
          that.tableReport[1].amount1 =
            "感知觉与沟通：" +
            that.formData.params.perceptionCommunication.level +
            "级";
          that.tableReport[1].amount2 =
            "社会参与：" +
            that.formData.params.socialParticipation.level +
            "级";

          // 卡片
          that.tableData1.forEach((item, index) => {
            // 判断失能状况
            if (item.id - 1 == that.formData.params.evaluation.firstResult) {
              // 判断日常活动
              if (that.formData.params.dailyActivity.level == item.dayActive) {
                item.dayActive =
                  item.dayActive +
                  '<span style="color:red;">' +
                  " √" +
                  "</span>";

                // 判断精神状态
                if (that.formData.params.mental.level == 0) {
                  item.spiritAmount0 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.mental.level == 1) {
                  item.spiritAmount1 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.mental.level == 2) {
                  item.spiritAmount2 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.mental.level == 3) {
                  item.spiritAmount3 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                // 判断感知状态
                if (that.formData.params.perceptionCommunication.level == 0) {
                  item.perceptionAmount0 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.perceptionCommunication.level == 1) {
                  item.perceptionAmount1 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.perceptionCommunication.level == 2) {
                  item.perceptionAmount2 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.perceptionCommunication.level == 3) {
                  item.perceptionAmount3 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                //  判断社会参与
                if (that.formData.params.socialParticipation.level == 0) {
                  item.socialAmount0 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.socialParticipation.level == 1) {
                  item.socialAmount1 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.socialParticipation.level == 2) {
                  item.socialAmount2 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
                if (that.formData.params.socialParticipation.level == 3) {
                  item.socialAmount3 =
                    '<span style="color:red;">' + " √" + "</span>";
                }
              }
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 选择选项
    changepitch(index, index1) {
      this.list[index].value = index1;
    },
    // 退出
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({ path: this.redirect || "/login" }).catch(() => {});
      });
    },

    objectSpan({ row, column, rowIndex, columnIndex }) {
      // 合并行  产品名字相同合并
      if (columnIndex == 0) {
        if (row.id == 7) {
          return [1, 3];
        } else if (
          rowIndex === 0 ||
          row.name != this.tableReport[rowIndex - 1].name
        ) {
          let rowspan = 0;
          this.tableReport.forEach((element) => {
            if (element.id === row.id) {
              rowspan++;
            }
          });
          return [rowspan, 1];
        } else {
          return [0, 0];
        }
      }
      // 行合并
      if (row.id == 2 || row.id == 3 || row.id == 4) {
        if (columnIndex == 1) {
          return [1, 3];
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        if (row.id == 5) {
          return [1, 14];
        } else if (
          rowIndex === 0 ||
          row.level != this.tableData1[rowIndex - 1].level
        ) {
          let rowspan = 0;
          this.tableData1.forEach((element) => {
            if (element.level === row.level) {
              rowspan++;
            }
          });
          return [rowspan, 1];
        } else {
          return [0, 0];
        }
      }
    },
    // 自定义列背景色当前行row、当前列column、当前行号rowIndex、当前列号columnIndex四个属性。
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        if (rowIndex == 0) {
          return "background:#fcd5b4;";
        } else if (rowIndex == 4) {
          return "background:#e6b9b8;";
        } else if (rowIndex == 5) {
          return "background:#c5d9f1;";
        } else if (rowIndex == 9) {
          return "background:#0070c0;";
        } else if (rowIndex == 10) {
          return "background:#ccc0da;";
        } else if (rowIndex == 14) {
          return "background:#a5a5a5;";
        }
      }
      if (rowIndex == 0) {
        if (columnIndex == 2) {
          return "background:#fcd5b4;";
        }
        if (columnIndex == 6) {
          return "background:#fcd5b4;";
        }
        if (columnIndex == 10) {
          return "background:#fcd5b4;";
        }
        if (columnIndex == 11) {
          return "background:#fcd5b4;";
        }
      }
      if (rowIndex == 4) {
        if (columnIndex == 3) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 4) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 5) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 7) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 8) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 9) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 12) {
          return "background:#e6b9b8;";
        }
        if (columnIndex == 13) {
          return "background:#e6b9b8;";
        }
      }
      if (rowIndex == 5) {
        if (columnIndex == 2) {
          return "background:#c5d9f1;";
        }
        if (columnIndex == 3) {
          return "background:#c5d9f1;";
        }
        if (columnIndex == 6) {
          return "background:#c5d9f1;";
        }
        if (columnIndex == 7) {
          return "background:#c5d9f1;";
        }

        if (columnIndex == 10) {
          return "background:#c5d9f1;";
        }
        if (columnIndex == 11) {
          return "background:#c5d9f1;";
        }
      }
      if (rowIndex == 9) {
        if (columnIndex == 4) {
          return "background:#0070c0;";
        }
        if (columnIndex == 5) {
          return "background:#0070c0;";
        }
        if (columnIndex == 8) {
          return "background:#0070c0;";
        }
        if (columnIndex == 9) {
          return "background:#0070c0;";
        }
        if (columnIndex == 13) {
          return "background:#0070c0;";
        }
        if (columnIndex == 12) {
          return "background:#0070c0;";
        }
      }
      if (rowIndex == 10) {
        if (columnIndex == 2) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 3) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 4) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 6) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 7) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 8) {
          return "background:#ccc0da;";
        }

        if (columnIndex == 10) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 11) {
          return "background:#ccc0da;";
        }
        if (columnIndex == 12) {
          return "background:#ccc0da;";
        }
      }
      if (rowIndex == 14) {
        if (columnIndex == 4) {
          return "background:#a5a5a5;";
        }
        if (columnIndex == 5) {
          return "background:#a5a5a5";
        }
        if (columnIndex == 8) {
          return "background:#a5a5a5;";
        }
        if (columnIndex == 9) {
          return "background:#a5a5a5";
        }
        if (columnIndex == 12) {
          return "background:#a5a5a5;";
        }
        if (columnIndex == 13) {
          return "background:#a5a5a5";
        }
      }
      if (rowIndex == 15) {
        return "background:#5a5a5a;";
      }
    },
  },
};
</script>
<style  scoped>
div /deep/.el-form-item__content {
  text-align: left;
}

div /deep/.el-form-item--large .el-form-item__content {
  text-align: center;
}
div /deep/.el-form-item {
  width: 80vw;
  margin: 0 auto;
}

div /deep/.el-radio__label {
  color: #000000 !important;
}
div /deep/.el-radio__inner::after {
  width: 14px !important;
  height: 14px !important;
  background-color: #3276ff !important;
}
div
  /deep/.el-checkbox__input.is-disabled.is-checked
  .el-checkbox__inner::after {
  border-color: #3276ff !important;
}
div /deep/ .el-checkbox__label {
  color: #000000 !important;
}
</style>
<style lang="less" scoped>
.top {
  padding: 30px 0;
  display: flex;
  align-items: center;
  color: #fff;
  background: #3174ff;
  cursor: pointer;
}
.top .homepage {
  margin-left: 85%;
  margin-right: 1%;
  cursor: pointer;
}
.top img {
  margin-left: 1%;
  cursor: pointer;
}
.lable {
  font-size: 24px;
  margin: 30px 0;
  color: #333;
}
.lable :nth-child(1) {
  text-align: right;
}
.lable :nth-child(2) {
  text-align: left;
  padding-left: 48px;
}
.box {
  // width: 100%;
  box-sizing: border-box;
  padding: 30px 100px;
}

.userinfo {
  display: flex;
  padding: 1vw 0;
}
.label {
  width: 150px;
  text-align: left;
  padding: 0 14px;
  font-size: 14px;
  color: #606266;
}

h2 {
  text-align: left;
  padding-left: 5vw;
}
.title {
  background: #3276ff;
  color: #fff;
  height: 8vh;
  line-height: 8vh;
  margin-bottom: 2vh;
}
.pitchtitel {
  color: #434343;
  font-size: 1.3vw;
  font-weight: bold;
}
.pitchon {
  // border: 1px solid #3276ff;
  background: #7aa6ff;
  color: #fff;
  width: 80%;
  margin: 0.5vw auto;
  border-radius: 5px;
  padding: 1vw 0;
  cursor: pointer;
}
.pitchoff {
  border: 2px solid #dedede;
  border-radius: 5px;
  width: 80%;
  margin: 0.5vw auto;
  padding: 1vw 0;
  cursor: pointer;
}
.totalpoints {
  border: 2px solid #7aa6ff;
  border-radius: 5px;
  width: 80%;
  padding: 1vw 0;
  margin: 0.5vw auto;
}
.totaltitle {
  color: #7aa6ff;
  font-size: 1.1vw;
}
.totalgrade {
  white-space: nowrap;
  color: #fd221f;
  padding: 0 0.8vw;
}
.totalgrade2 {
  width: 100%;
  //   white-space: nowrap;
  text-align: left;
  color: #fd221f;
  padding: 0 0.8vw;
}
.gradeBox {
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.content {
  color: red;
  text-align: left;
}
h1 {
  padding: 2vh 0;
}
</style>
