<template>
  <div class="box">
    <el-form
      ref="elForm"
      size="medium"
      label-width="150px"
      label-position="left"
    >
      <el-col :span="24">
        <h2>老人能力评估表</h2>
      </el-col>
      <el-col :span="24">
        <div class="title">感知觉与沟通评估表</div>
      </el-col>
      <div>
        <div v-for="(item, index) in list" :key="index">
          <div class="pitchtitel">{{ item.name }}</div>
          <div
            v-for="(item1, index1) in item.children"
            :key="index1"
            :class="index1 == item.value ? 'pitchon' : 'pitchoff'"
            @click="changepitch(index, index1)"
          >
            {{ item1.name }}
          </div>
          <el-divider></el-divider>
        </div>
      </div>

      <div class="totalpoints">
        <div class="totaltitle">沟通交流总分</div>
        <div>{{ allcount }}分</div>
        <div class="totalgrade">上述 4 个项目得分之和</div>
      </div>

      <div class="totalpoints">
        <div class="totaltitle">沟通交流分级</div>
        <div>{{ level }}级</div>
        <div class="gradeBox">
          <div class="totalgrade2" v-for="(item, index) in grade" :key="index">
            {{ item }}
          </div>
        </div>
      </div>

      <el-form-item size="large" style="text-align: center">
        <el-button type="primary" @click="lastStep">上一步</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button type="primary" @click="submitForm">下一步</el-button>
        <el-button @click="resetForm">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import perceptionCommunication from "../../js/perceptionCommunication.js";

import { savePerceptionCommunication, detail } from "@/api/login/login";
export default {
  components: {},
  props: {
    elderlyid: {
     type: [String, Number],
    },
  },
  data() {
    return {
      list: perceptionCommunication,
      grade: [
        "0 能力完好：首先在意识清晰的前提下，视力评为0或1“且”听力评为0或1“且”沟通评为0三种情况都需存在的，最终为能力完好",
        "1 轻度受损：如果意识清晰的前提下，视力评为2分“或”听力评为2分“或”沟通评为1分，这三种有一种即为轻度受损",
        "2 中度受损：如果意识清晰的前提下，视力评为3分“或”听力评为3分“或”沟通评为2分，这三种有一种即为中度受损；若在嗜睡的前提下，视力评为3分或3分以下“或”听力评为3分或3分以下“或”沟通评为2分或2分以下，（若为嗜睡时，视力听力不超过3分，沟通不超过2分，即为中度受损）",
        "3 重度受损：如果为昏睡或者昏迷，直接为重度，或者，在意识清晰或嗜睡的前提下，视力评为4分“或”听力评为4分“或”沟通评为3分，三个中有一个符合即为重度",
      ],
      allcount: "",
      level: "",
      oldid: this.elderlyid,
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.oldid) {
      this.get_detail();
    }
    this.list.forEach((item) => {
      item.value = -1;
    });
  },
  mounted() {},
  methods: {
    submitForm() {
      // 遍历筛选
      for (let i in this.list) {
        if (this.list[i].value == -1) {
          this.$message({
            message: "请选择" + this.list[i].name,
          });
          return false;
        }
      }
      // 获取选择数据
      let arr = {};
      this.list.forEach((item, index) => {
        arr[item.key] = item.value;
      });
      let data = new FormData();
      data.append("consciousnessLevel", arr.consciousnessLevel);
      data.append("vision", arr.vision);
      data.append("hearing", arr.hearing);
      data.append("communication", arr.communication);
      data.append("infoId", this.oldid);
      data.append("id", this.oldid);
      savePerceptionCommunication(data)
        .then((result) => {
          this.$emit("changefromloist", 4, "数组改变了");
          this.$message({
            message: result.msg,
            type: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
      this.list.forEach((item) => {
      item.value = -1;
    });
    },
         //上一步
    lastStep(){
        this.$emit("changefromloist", 2);
    },
    // 选择选项
    changepitch(index, index1) {
      this.list[index].value = index1;
    },
    // 老人信息详情
    get_detail() {
      let data = new FormData();
      data.append("id", this.oldid);
      detail(data)
        .then((result) => {
          if (result.data.params.perceptionCommunication) {
            this.allcount = result.data.params.perceptionCommunication.score;
            this.level = result.data.params.perceptionCommunication.level;
            this.list[0].value =
              result.data.params.perceptionCommunication.consciousnessLevel;
            this.list[1].value =
              result.data.params.perceptionCommunication.vision;
            this.list[2].value =
              result.data.params.perceptionCommunication.hearing;
            this.list[3].value =
              result.data.params.perceptionCommunication.communication;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style  scoped>
div /deep/.el-form-item__content {
  text-align: left;
}

div /deep/.el-form-item--large .el-form-item__content {
  text-align: center;
}
</style>
<style lang="less" scoped>
.box {
  // width: 100%;
  box-sizing: border-box;
  padding: 30px 100px;
}
h2 {
  text-align: left;
  padding-left: 1vw;
}
.title {
  background: #3276ff;
  color: #fff;
  height: 8vh;
  line-height: 8vh;
  margin-bottom: 2vh;
}
.pitchtitel {
  color: #434343;
  font-size: 1.3vw;
  font-weight: bold;
}
.pitchon {
  // border: 1px solid #3276ff;
  background: #7aa6ff;
  color: #fff;
  width: 80%;
  margin: 0.5vw auto;
  border-radius: 5px;
  padding: 1vw 0;
  cursor: pointer;
}
.pitchoff {
  border: 2px solid #dedede;
  border-radius: 5px;
  width: 80%;
  margin: 0.5vw auto;
  padding: 1vw 0;
  cursor: pointer;
}
.totalpoints {
  border: 2px solid #7aa6ff;
  border-radius: 5px;
  width: 80%;
  padding: 1vw 0;
  margin: 0.5vw auto;
}
.totaltitle {
  color: #7aa6ff;
  font-size: 1.1vw;
}
.totalgrade {
  white-space: nowrap;
  color: #fd221f;
  padding: 0 0.8vw;
}
.totalgrade2 {
  //   white-space: nowrap;
  text-align: left;
  color: #fd221f;
  padding: 0 0.8vw;
}
.gradeBox {
  text-align: center;
  margin: 0 auto;
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
}
</style>
