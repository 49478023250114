module.exports = [{
	id: 0,
	name: "意识水平",
	key: "consciousnessLevel",
	model: "请选择",
	value: -1,
	children: [
            {
                name:"神志清醒，对周围环境警觉",
			isdiaable:false
		},
		{
			name:"嗜睡",
			isdiaable:false
		},
		{
			name:"昏睡",
			isdiaable:false
		},
		{
			name:"昏迷",
			isdiaable:false
		},
		
	]
}, {
	id: 1,
	name: "视力",
	key: "vision",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"能看清书报上的标准字体",
			isdiaable:false
		},
		{
			name:"能看清楚大字体，但看清书报上的标准字体",
			isdiaable:false
		},
		{
			name:"视力有限，看不清报纸大标题，但能辨认物体",
			isdiaable:false
		},
		{
			name:"辨认物体有困难，但眼睛能跟随物体移动，只能看到光、颜色和形状",
			isdiaable:false
		},
		{
			name:"没有视力，眼睛不能随物体移动",
			isdiaable:false
		}
		
	]
}, {
	id: 2,
	name: "听力",
	key: "hearing",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可正常交谈",
			isdiaable:false
		},
		{
			name:"在轻声说话或说话距离超过2米时听不清",
			isdiaable:false
		},
		{
			name:"正常交流有些困难",
			isdiaable:false
		},
		{
			name:"讲话者大声说话或说话很慢才能部分听见",
			isdiaable:false
		},
		{
			name:"完全听不见",
			isdiaable:false
		},
	]
}, {
	id: 3,
	name: "沟通交流",
	key: "communication",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"无困难",
			isdiaable:false
		},
		{
			name:"能够表达自己的需要及理解别人的话",
			isdiaable:false
		},
		{
			name:"表达需要或理解有困难",
			isdiaable:false
		},
		{
			name:"不能表达需要或理解他人的话",
			isdiaable:false
		},
	]
}]
