<template>
  <div v-loading="loading">
    <el-cascader
      ref="sysCascader"
      :style="{ width: width ? width : '100%' }"
      v-model="form.areaIdArr"
      :props="prop"
      @change="handleChange($event)"
      clearable
      :placeholder="placeholder"
    ></el-cascader>
  </div>
</template>

<script>
import { treeList } from "@/api/login/login.js";
export default {
  props: ["placeholder", "width"],
  data() {
    return {
      loading: true,
      prop: {
        label: "label",
        value: "id",
        lazy: true, //开启异步加载
        checkStrictly: false,
        lazyLoad: (node, resolve) => {
          const { value, level } = node;
          let data = {
            parentId: node.value,
          };
          console.log(node);
          if (node.level == 0) {
            data.parentId = 0;
          }
		  console.log()
          console.log(data.parentId);
          treeList(data).then((res) => {
            console.log("zhix2");
            if (level == 4) {
              resolve(
                res.data.map((item) => {
                  item.leaf = "leaf"; //这个必须加，就是让组件识别是不是最后一级
                  return item;
                })
              );
              this.loading = false;
            } else {
              resolve(res.data);
              this.loading = false;
            }
          });
        },
      },
      form: {
        areaIdArr: null,
        areaNameArr: null,
      },
      areaOptions: [],
    };
  },

  methods: {
    getAreaOptions(data) {
      treeList(data).then((res) => {
        if (level == 4) {
          resolve(
            res.data.map((item) => {
              item.leaf = "leaf"; //这个必须加，就是让组件识别是不是最后一级
              return item;
            })
          );
        } else {
          resolve(res.data);
        }
      });
    },
    /** 加载子节点数据 **/
    handleChange(e) {
      console.log(e);
      if (e != undefined) {
        if (e.length === 0) {
          this.form.areaIdArr = null;
          this.form.areaNameArr = null;
        } else {
          var pathLabelsArr =
            this.$refs.sysCascader.getCheckedNodes()[0].pathLabels;
          this.form.areaIdArr = e;
          this.form.areaNameArr = pathLabelsArr;
        }
        this.$emit("getAreaFrom", this.form);
      }
    },
    // 置空
    reset() {
      let obj = {};
      obj.stopPropagation = () => {};
      try {
        this.$refs.sysCascader.clearValue(obj);
      } catch (err) {
        this.$refs.sysCascader.handleClear(obj);
      }
    },
    // 回显赋值
    getAreaEcho(val) {
      var arr = [
        val.provinceId,
        val.cityId,
        val.areaId,
        val.streetId,
        val.communityId,
      ];
      this.form.areaIdArr = arr;
    },
  },
};
</script>

<style></style>
