import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get('TokenKey')
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove('TokenKey')
}

// 评估原因
export function evaluatecauseOptions() {
  return [{
      label: "接受服务前评估",
      value: 0,
    },
    {
      label: "接受服务后评估",
      value: 1,
    },
    {
      label: "状态发生变化后的及时评估",
      value: 2,
    },
    {
      label: "因评估结果有疑问进行的复评",
      value: 3,
    },
  ]
}
// 民族
export function nationOptions() {
  return [{
      label: "汉族",
      value: 0,
    },
    {
      label: "其他少数民族",
      value: 1,
    },
  ]
}
// 文化程度
export function educationOptions() {
  return [{
      label: "文盲",
      value: 0,
    },
    {
      label: "小学",
      value: 1,
    },
    {
      label: "初中",
      value: 2,
    },
    {
      label: "高中/技校/中专",
      value: 3,
    },
    {
      label: "大专专科及以上",
      value: 4,
    },
    {
      label: "不详",
      value: 5,
    },
  ]
}
// 婚姻状况
export function marriageOptions() {
  return [{
      label: "未婚",
      value: 0,
    },
    {
      label: "已婚",
      value: 1,
    },
    {
      label: "丧偶",
      value: 2,
    },
    {
      label: "离婚",
      value: 3,
    },
    {
      label: "未说明婚姻情况",
      value: 4,
    },
  ]
}
//居住
export function liveOptions() {
  return [{
      label: "独居",
      value: 0,
    },
    {
      label: "与配偶/伴侣居住",
      value: 1,
    },
    {
      label: "与子女居住",
      value: 2,
    },
    {
      label: "与父母居住",
      value: 3,
    },
    {
      label: "与兄弟姐妹居住",
      value: 4,
    },
    {
      label: "与其他亲属居住",
      value: 5,
    },
    {
      label: "与非亲属关系的人居住",
      value: 6,
    },
    {
      label: "养老机构",
      value: 7,
    },
  ]
}
// 经济来源
export function moneySourceOptions() {
  return [{
      label: "退休金/养老金",
      value: 0,
    },
    {
      label: "子女补贴",
      value: 1,
    },
    {
      label: "亲友资助",
      value: 2,
    },
    {
      label: "其他补贴",
      value: 3,
    },
  ]
}
//疾病诊断-痴呆
export function dementiaOptions() {
  return [{
      label: "无",
      value: 0,
    },
    {
      label: "轻度",
      value: 1,
    },
    {
      label: "中度",
      value: 2,
    },
    {
      label: "重度",
      value: 3,
    },
  ]
}

//疾病诊断-精神疾病
export function spiritOptions() {
  return [{
      label: "无",
      value: 0,
    },
    {
      label: "精神分裂症",
      value: 1,
    },
    {
      label: "双相情感障碍",
      value: 2,
    },
    {
      label: "偏执性精神障碍",
      value: 3,
    },
    {
      label: "分裂情感性障碍",
      value: 4,
    },
    {
      label: "癫痫所致精神障碍",
      value: 5,
    },
    {
      label: "精神发育迟滞伴发精神障碍",
      value: 6,
    },
  ]
}

//近30天内意外事件-走失
export function lostOptions() {
  return [{
      label: "无",
      value: 0,
    },
    {
      label: "发生过 1 次",
      value: 1,
    },
    {
      label: "发生过 2 次",
      value: 2,
    },
    {
      label: "发生过 3 次及以上",
      value: 3,
    },
  ]
}
//近30天内意外事件-跌倒
export function fallOptions() {
  return [{
      label: "无",
      value: 0,
    },
    {
      label: "发生过 1 次",
      value: 1,
    },
    {
      label: "发生过 2 次",
      value: 2,
    },
    {
      label: "发生过 3 次及以上",
      value: 3,
    },
  ]
}
//近30天内意外事件-噎食
export function blockingOptions() {
  return [{
      label: "无",
      value: 0,
    },
    {
      label: "发生过 1 次",
      value: 1,
    },
    {
      label: "发生过 2 次",
      value: 2,
    },
    {
      label: "发生过 3 次及以上",
      value: 3,
    },
  ]
}
//近30天内意外事件-自杀
export function suicideOptions() {
  return [{
      label: "无",
      value: 0,
    },
    {
      label: "发生过 1 次",
      value: 1,
    },
    {
      label: "发生过 2 次",
      value: 2,
    },
    {
      label: "发生过 3 次及以上",
      value: 3,
    },
  ]
}

// 信息提供者与老人的关系
export function provideOldmanOptions() {
  return [{
      label: "配偶",
      value: 0,
    },
    {
      label: "子女",
      value: 1,
    },
    {
      label: "其他亲属",
      value: 2,
    },
    {
      label: "雇佣者",
      value: 3,
    },
    {
      label: "本人",
      value: 4,
    },
    {
      label: "其他",
      value: 5,
    },
  ]
}