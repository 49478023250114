import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/icon/iconfont.css"
import ElementUI from 'element-ui';
import Cookies from "js-cookie";
import "./permission";
import 'element-ui/lib/theme-chalk/index.css';
import Treeselect from '@riophae/vue-treeselect'
import vueToPdf from 'vue-to-pdf';
import SvgIcon from 'vue-svgicon'
import htmlToPdf from '@/views/server/htmlToPdf';
Vue.use(SvgIcon, {
    tagName: 'svg-icon'
});
Vue.use(vueToPdf);
Vue.use(Cookies);
/**
 * 自定义全局组件（区划-表单用）
 */
 import AreaFormCascader from '@/components/areaCascaer.vue'
 Vue.component("AreaFormCascader", AreaFormCascader);
Vue.use(htmlToPdf)
Vue.prototype.$apiUrl='https://sypg.ybkyl.com/'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.component('Treeselect',Treeselect)
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
