module.exports = [

    {
        title: '日常生活活动评估表',
        grade: [
            "0 能力完好：总分 100 分",
            "1 轻度受损：总分 65-95 分",
            "2 中度受损：总分 45-60 分",
            "3 重度受损：总分≤40 分",
        ],
        totaltitle: '日常生活总分',
        totalgrade: '上述 10 个项目得分之和',
        total: '',
        totalrank: '日常生活分级',
        rank: '',

        children: [{
                id: 0,
                name: "进食",
                key: "eat",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立进食",
                        isdiaable: false
                    },
                    {
                        name: "需部分帮助",
                        isdiaable: false
                    },
                    {
                        name: "需极大帮助、完全依赖他人或留置营养管",
                        isdiaable: false
                    }
                ]
            },
            {
                id: 1,
                name: "洗澡",
                key: "bathe",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立完成",
                        isdiaable: false
                    },
                    {
                        name: "需他人帮助",
                        isdiaable: false
                    },


                ]
            },
            {
                id: 2,
                name: "修饰",
                key: "beautify",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立完成",
                        isdiaable: false
                    },
                    {
                        name: "需他人帮助",
                        isdiaable: false
                    },
                ]
            },
            {
                id: 3,
                name: "穿衣",
                key: "dress",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立完成",
                        isdiaable: false
                    },
                    {
                        name: "需他人帮助",
                        isdiaable: false
                    },
                    {
                        name: "需极大帮助或完全依赖他人",
                        isdiaable: false
                    },
                ]
            },
            {
                id: 4,
                name: "大便控制",
                key: "stoolControl",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可控制大便",
                        isdiaable: false
                    },
                    {
                        name: "偶尔失控或需要他人提示",
                        isdiaable: false
                    },
                    {
                        name: "完全失控",
                        isdiaable: false
                    },
                ]
            },
            {
                id: 5,
                name: "小便控制",
                key: "urinationControl",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可控制小便",
                        isdiaable: false
                    },
                    {
                        name: "偶尔失控或需要他人提示",
                        isdiaable: false
                    },
                    {
                        name: "完全失控或留置导尿管",
                        isdiaable: false
                    },
                ]
            },
            {
                id: 6,
                name: "如厕",
                key: "toilet",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立完成",
                        isdiaable: false
                    },
                    {
                        name: "需部分帮助",
                        isdiaable: false
                    },
                    {
                        name: "需极大帮助或完全依赖他人",
                        isdiaable: false
                    },
                ]
            },
            {
                id: 7,
                name: "床椅转移",
                key: "bedChairTransfer",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立完成",
                        isdiaable: false
                    },
                    {
                        name: "需部分帮助",
                        isdiaable: false
                    },
                    {
                        name: "需极大帮助",
                        isdiaable: false
                    },
                    {
                        name: "完全依赖他人",
                        isdiaable: false
                    },

                ]
            },
            {
                id: 8,
                name: "平地行走",
                key: "levelWalking",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立在平地上行走45m",
                        isdiaable: false
                    },
                    {
                        name: "需部分帮助",
                        isdiaable: false
                    },
                    {
                        name: "需极大帮助",
                        isdiaable: false
                    },
                    {
                        name: "完全依赖他人",
                        isdiaable: false
                    },
                ]
            },
            {
                id: 9,
                name: "上下楼梯",
                key: "stairActivity",
                model: "请选择",
                value: -1,
                children: [{
                        name: "可独立上下楼梯",
                        isdiaable: false
                    },
                    {
                        name: "需部分帮助",
                        isdiaable: false
                    },
                    {
                        name: "需极大帮助或完全依赖他人",
                        isdiaable: false
                    },
                ]
            }
        ]
    },
    {
        title: '精神状态评估表',
        grade: [
            "0 能力完好：总分 0 分",
            "1 轻度受损：总分 1 分",
            "2 中度受损：总分 2-3分",
            "3 重度受损：总分 4-6分",
        ],
        totaltitle: '精神状态总分',
        totalgrade: '上述 3 个项目得分之和',
        total: '',
        totalrank: '精神状态分级',
        rank: '',
        children: [{
            id: 0,
            name: "认知功能",
            key: "cognitive",
            model: "请选择",
            value: -1,
            children: [{
                    name: "画钟正确",
                    isdiaable: false
                },
                {
                    name: "画钟错误或只回忆出0-1个词",
                    isdiaable: false
                },
                {
                    name: "已确诊为认知障碍",
                    isdiaable: false
                },
            ]
        }, {
            id: 1,
            name: "攻击行为",
            key: "aggressiveBehavior",
            model: "请选择",
            value: -1,
            children: [{
                    name: "无身体攻击行为和语言攻击行为",
                    isdiaable: false
                },
                {
                    name: "每月有几次身体攻击行为或每周有几次语言攻击行为",
                    isdiaable: false
                },
                {
                    name: "每周有几次身体攻击行为或每日有语言攻击行为",
                    isdiaable: false
                },
            ]
        }, {
            id: 2,
            name: "抑郁症状",
            key: "depressiveSymptoms",
            model: "请选择",
            value: -1,
            children: [{
                    name: "无",
                    isdiaable: false
                },
                {
                    name: "情绪低落、不爱说话、不爱梳洗、不爱活动",
                    isdiaable: false
                },
                {
                    name: "有自杀念头或自杀行为",
                    isdiaable: false
                },
            ]
        }]
    },
    {
        title: '感知觉与沟通评估表',
        grade: [
            "0 能力完好：首先在意识清晰的前提下，视力评为0或1“且”听力评为0或1“且”沟通评为0三种情况都需存在的，最终为能力完好",
            "1 轻度受损：如果意识清晰的前提下，视力评为2分“或”听力评为2分“或”沟通评为1分，这三种有一种即为轻度受损",
            "2 中度受损：如果意识清晰的前提下，视力评为3分“或”听力评为3分“或”沟通评为2分，这三种有一种即为中度受损；若在嗜睡的前提下，视力评为3分或3分以下“或”听力评为3分或3分以下“或”沟通评为2分或2分以下，（若为嗜睡时，视力听力不超过3分，沟通不超过2分，即为中度受损）",
            "3 重度受损：如果为昏睡或者昏迷，直接为重度，或者，在意识清晰或嗜睡的前提下，视力评为4分“或”听力评为4分“或”沟通评为3分，三个中有一个符合即为重度",
        ],
        totaltitle: '沟通交流总分',
        totalgrade: '上述 4 个项目得分之和',
        total: '',
        totalrank: '沟通交流分级',
        rank: '',
        children: [{
            id: 0,
            name: "意识水平",
            key: "consciousnessLevel",
            model: "请选择",
            value: -1,
            children: [{
                    name: "神志清醒，对周围环境警觉",
                    isdiaable: false
                },
                {
                    name: "嗜睡",
                    isdiaable: false
                },
                {
                    name: "昏睡",
                    isdiaable: false
                },
                {
                    name: "昏迷",
                    isdiaable: false
                },

            ]
        }, {
            id: 1,
            name: "视力",
            key: "vision",
            model: "请选择",
            value: -1,
            children: [{
                    name: "能看清书报上的标准字体",
                    isdiaable: false
                },
                {
                    name: "能看清楚大字体，但看清书报上的标准字体",
                    isdiaable: false
                },
                {
                    name: "视力有限，看不清报纸大标题，但能辨认物体",
                    isdiaable: false
                },
                {
                    name: "辨认物体有困难，但眼睛能跟随物体移动，只能看到光、颜色和形状",
                    isdiaable: false
                },
                {
                    name: "没有视力，眼睛不能随物体移动",
                    isdiaable: false
                }

            ]
        }, {
            id: 2,
            name: "听力",
            key: "hearing",
            model: "请选择",
            value: -1,
            children: [{
                    name: "可正常交谈",
                    isdiaable: false
                },
                {
                    name: "在轻声说话或说话距离超过2米时听不清",
                    isdiaable: false
                },
                {
                    name: "正常交流有些困难",
                    isdiaable: false
                },
                {
                    name: "讲话者大声说话或说话很慢才能部分听见",
                    isdiaable: false
                },
                {
                    name: "完全听不见",
                    isdiaable: false
                },
            ]
        }, {
            id: 3,
            name: "沟通交流",
            key: "communication",
            model: "请选择",
            value: -1,
            children: [{
                    name: "无困难",
                    isdiaable: false
                },
                {
                    name: "能够表达自己的需要及理解别人的话",
                    isdiaable: false
                },
                {
                    name: "表达需要或理解有困难",
                    isdiaable: false
                },
                {
                    name: "不能表达需要或理解他人的话",
                    isdiaable: false
                },
            ]
        }]
    },
    {
        title: '社会参与评估表',
        grade: [
            "0 能力完好：总分 0-2 分",
            "1 轻度受损：总分 3-7 分",
            "2 中度受损：总分 8-13 分",
            "3 重度受损：总分 14-20 分",
        ],
        totaltitle: '社会参与总分',
        totalgrade: '上述 5 个项目得分之和',
        total: '',
        totalrank: '社会参与分级',
        rank: '',
        children: [{
            id: 0,
            name: "生活能力",
            key: "lifeAbility",
            model: "请选择",
            value: -1,
            children: [{
                    name: "除个人生活自理外能料理家务或当家管理事务",
                    isdiaable: false
                },
                {
                    name: "能做家务但欠好，家庭事务安排欠条理",
                    isdiaable: false
                },
                {
                    name: "只有在他人帮助下才能做些家务，但质量不好",
                    isdiaable: false
                },
                {
                    name: "个人基本生活事务能自理，在督促下可洗漱",
                    isdiaable: false
                },
                {
                    name: "基本生活事务需要部分帮助或完全依赖他人帮助",
                    isdiaable: false
                },
            ]
        }, {
            id: 1,
            name: "工作能力",
            key: "workAbility",
            model: "请选择",
            value: -1,
            children: [{
                    name: "工作可照常进行",
                    isdiaable: false
                },
                {
                    name: "工作能力有所下降",
                    isdiaable: false
                },
                {
                    name: "明显不如以往，部分遗忘",
                    isdiaable: false
                },
                {
                    name: "熟练工作片段保留，技能全部遗忘",
                    isdiaable: false
                },
                {
                    name: "全部遗忘",
                    isdiaable: false
                },
            ]
        }, {
            id: 2,
            name: "时间/空间定向",
            key: "timeSpace",
            model: "请选择",
            value: -1,
            children: [{
                    name: "时间观念清楚，可单独出远门，能很快掌握新环境的方位",
                    isdiaable: false
                },
                {
                    name: "时间观念有些下降，不知回家路线",
                    isdiaable: false
                },
                {
                    name: "时间观念较差，不知道方位",
                    isdiaable: false
                },
                {
                    name: "时间观念很差，对现住地不知名称和方位",
                    isdiaable: false
                },
                {
                    name: "无时间观念",
                    isdiaable: false
                },
                // "时间观念清楚，可单独出远门，能很快掌握新环境的方位",
                // "时间观念有些下降，不知回家路线",
                // "时间观念较差，不知道方位",
                // "时间观念很差，对现住地不知名称和方位",
                // "无时间观念"
            ]
        }, {
            id: 3,
            name: "人物定向",
            key: "personalOrientation",
            model: "请选择",
            value: -1,
            children: [{
                    name: "知道周围人们的关系，可用适当称呼",
                    isdiaable: false
                },
                {
                    name: "只知家中亲密近亲的关系",
                    isdiaable: false
                },
                {
                    name: "只能称呼家中人，不知其关系",
                    isdiaable: false
                },
                {
                    name: "只认识常同住的亲人，可辨熟人和生人",
                    isdiaable: false
                },
                {
                    name: "只认识保护人",
                    isdiaable: false
                },

                // "知道周围人们的关系，可用适当称呼",
                // "只知家中亲密近亲的关系",
                // "只能称呼家中人，不知其关系",
                // "只认识常同住的亲人，可辨熟人和生人",
                // "只认识保护人"
            ]
        }, {
            id: 4,
            name: "社会交往能力",
            key: "societyAbility",
            model: "请选择",
            value: -1,
            children: [{
                    name: "参与社会，在社会环境有一定的适应能力",
                    isdiaable: false
                },
                {
                    name: "能适应单纯环境，主动接触人，不能理解隐喻语",
                    isdiaable: false
                },
                {
                    name: "脱离社会，不会主动待人，容易上当受骗",
                    isdiaable: false
                },
                {
                    name: "谈吐内容不清楚，表情不恰当",
                    isdiaable: false
                },
                {
                    name: "难以与人接触",
                    isdiaable: false
                },
                // "参与社会，在社会环境有一定的适应能力",
                // "能适应单纯环境，主动接触人，不能理解隐喻语",
                // "脱离社会，不会主动待人，容易上当受骗",
                // "谈吐内容不清楚，表情不恰当",
                // "难以与人接触"
            ]
        }]
    }


]