<!--  -->
<template>
  <div class="backBox">
    <div class="main">
      <div class="login">
        <div class="shouye" @click="go_home"> {{istoken == false?'首页':'个人中心'}}</div>
        <img
          src="../../static/images/login.png"
          alt=""
          class="login_pic"
          v-if="istoken == false"
          @click="tologin"
        />
        <el-dropdown v-if="istoken == true">
        <img src="../../static/images/headerlogo.png" alt="" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      </div>
      <!-- 第一个图 -->
      <div class="contentone">
        <div class="title">{{ title }}</div>
        <div class="detail">
          随着我国人口老龄化程度日趋严重，为了满足老年人养老服务的需求，<span>
            在参考美国、日本、澳大利亚、英国等国家及我国香港和台湾地区老年人能力评估工具的基础上编制了本标准。</span
          >标准的制订为老年人能力评估提供统一、规范和可操作的评估工具，科学划分老年人能力等级，作为政府制定养老政策，以及为老年人提供适宜养老服务的依据。
        </div>
        <div class="pg">
          <img src="../../static/images/pgu.png" alt="" @click="go_home" />
        </div>
      </div>
      <!-- 第二个图 -->
      <div class="contenttwo">
        <div class="left">
          <img src="../../static/images/phone.png" alt="" />
        </div>
        <div class="right">
          <div class="hint">{{ hint }}</div>
          <div class="detail2">{{ detail2 }}</div>
          <div class="detail3">
            <img src="../../static/images/qrcode.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="linkbox">
        <div class="link">
          <div class="linkleft">
            <div class="linktitle">友情链接</div>
            <div class="website">
              <div
                v-for="(item, index) in list"
                :key="index"
                @click="openpage(item.url)"
              >
                <span class="item">{{ item.name }} </span>&nbsp;|&nbsp;
              </div>
            </div>
          </div>
          <div class="linkright">
            <div class="linktitle">民政官网</div>
            <div class="website">
              <div
                v-for="(item, index) in list2"
                :key="index"
                @click="openpage(item.url)"
              >
                <span class="item"> {{ item.name }}</span
                >&nbsp; |&nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foter">
        <div>
          联系我们：18951015775（李先生）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          025-57018252（颐倍康养老）&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          地址：南京市雨花台区宁双路28号汇智大厦A座215
        </div>
        <div>
          版权所有：颐倍康南京养老服务有限公司 版权所有 2016-2020 all rights
          reserved
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "老年人评估",
      hint: "使用移动端评估更方便",
      detail2:
        "标准的制订为老年人能力评估提供统一、规范和可操作的评估工具，科学划分老年人能力等级，作为政府制定养老政策，以及为老年人提供适宜养老服务的依据。",
      list: [
        {
          name: "南京为老服务官网",
          url: "",
        },
        {
          name: "沈阳为老服务官网",
          url: "",
        },
        {
          name: "西宁为老服务官网",
          url: "",
        },

        {
          name: "鞍山为老服务官网",
          url: "",
        },
        {
          name: "泰州为老服务官网",
          url: "",
        },
        {
          name: "绍兴为老服务官网",
          url: "",
        },
        {
          name: "南京市高淳区为老服务官网",
          url: "",
        },
        {
          name: "泰州市泰兴市为老服务官网",
          url: "",
        },
        {
          name: "南昌市青山湖区为老服务官网",
          url: "",
        },
        {
          name: "景德镇昌江区为老服务官网",
          url: "",
        },
      ],
      list2: [
        {
          name: "中华人民共和国民政部",
          url: "http://www.mca.gov.cn/",
        },
        {
          name: "沈阳市民政局",
          url: "http://mzj.shenyang.gov.cn/",
        },
        {
          name: "西宁民政局",
          url: "",
        },
        {
          name: "鞍山民政局",
          url: "http://mzj.anshan.gov.cn/",
        },
        {
          name: "泰州民政局",
          url: "http://mzj.taizhou.gov.cn/",
        },
        {
          name: "绍兴民政局",
          url: "http://sxmz.sx.gov.cn/",
        },
        {
          name: "南京市高淳区民政局",
          url: "",
        },
        {
          name: "泰州市泰兴市民政局",
          url: "http://www.taixing.gov.cn/col/col54385/",
        },
        {
          name: "南昌市民政局",
          url: "http://mzj.nc.gov.cn/",
        },
        {
          name: "景德镇市民政局",
          url: "http://mz.jdz.gov.cn/",
        },
      ],
      istoken: false,
    };
  },

  components: {},

  computed: {},

  mounted() {
    let hasToken = localStorage.getItem("Token");
    if (hasToken) {
      this.istoken = true;
      // this.$router.push({
      //   path: "/home",
      // });
    }
  },

  methods: {
    openpage(url) {
      window.open(url, "_blank");
    },
    // 去登录
    tologin() {
      this.$router.push({
        path: "/login",
      });
    },
    // 首页
    go_home() {
      this.$router.push({
        path: "/home",
      });
    },
     // 退出
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({ path: this.redirect || "/login" }).catch(() => {});
      });
    },
  },
};
</script>
<style  scoped>
.element::-webkit-scrollbar {
  width: 0 !important;
}
body {
  margin: 0px;
  padding: 0px;
}
.backBox {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* min-height: 100vh; */
}
.main {
  width: 100%;
  height: 190vh;
  background-image: url("../../static/images/back.jpg");
  background-size: 100% 100%;
  /* background-repeat: no-repeat; */
}
.login {
  display: flex;
  align-items: center;
  color: #ffffff;
  padding: 30px 0;
  cursor: pointer;
}
.shouye {
  margin-left: 80%;
  cursor: pointer;
  margin-right: 1%;
}

.login_pic {
  margin-left: 2%;
  cursor: pointer;
}
.contentone {
  width: 35vw;
  margin: 15vh 10vh;
}
.title {
  color: #ffffff;
  font-size: 4vw;
  font-weight: bold;
  padding: 10px 0;
  cursor: pointer;
}
.detail {
  color: #ffffff;
  font-size: 1.5vw;
  line-height: 6vh;
  text-align: left;
  cursor: pointer;
}
.pg {
  padding-top: 20px;
  text-align: right;
  cursor: pointer;
}
.pg img {
  width: 13vw;
  height: 8vh;
}
.detail span {
  color: #d6d6d6;
  font-size: 1.6vw;
  font-weight: bold;
}
.contenttwo {
  display: flex;
}
.left {
  width: 50%;
}
.right {
  width: 50%;
  padding: 20vh 0;
}
.hint {
  text-align: left;
  color: #ffffff;
  font-size: 2vw;
  cursor: pointer;
}
.detail2 {
  margin-top: 4vh;
  width: 60%;
  text-align: left;
  color: #ffffff;
  font-size: 1vw;
  cursor: pointer;
}
.detail3 {
  margin-top: 5vh;
  width: 60%;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
}

.linkbox {
  background-color: #3f3f3f;
  /* height: 12vh; */
  padding: 1vh;
}
.link {
  width: 70vw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  /* background-color: #ffffff; */
}
.linkleft {
  color: #fff;
  width: 51%;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
}
.linkright {
  color: #fff;
  width: 46%;
  margin: 0 auto;
  text-align: left;
  overflow: hidden;
}
.linktitle {
  padding: 1vh 0 1vh;
}
.website {
  display: flex;
  flex-wrap: wrap;
}
.item {
  height: 30px;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  cursor: pointer;
}

.item:hover {
  height: 30px;
  font-size: 14px;
  color: #2f74ff;
  white-space: nowrap;
  cursor: pointer;
}
.item span:hover {
  height: 30px;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
}

.foter {
  padding: 10px;
  color: #ffffff;
  height: 6vh;
  background-color: #333333;
}
.foter div {
  line-height: 3vh;
}
html {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
html::-webkit-scrollbar {
  width: 0px;
}
</style>