import axios from "axios";
import {
	Notification,
	MessageBox,
	Message
} from 'element-ui'
import router from '@/router'
import store from '@/store'
// 创建axios实例
import {
	getToken
} from '@/utils/auth'
let that = this;
const service = axios.create({
	// baseURL: 'http://192.168.101.6:9060',
	baseURL: 'http://gc.ybkyl.com/prod-api/',
	timeout: 12000 // request timeout //设置请求时长
})
// 添加请求拦截器
const whiteList = ['login', 'sendCode','captchaImage']
service.interceptors.request.use(
	config => {
		// 在发送请求之前做些什么
		if ( config.url.includes('login') || config.url.includes('sendCode') || config.url.includes('captchaImage')) {
			config.headers["content-type"] = "application/x-www-form-urlencoded";
		} else {
			config.headers["Authorization"] = 'ybk_Bearer ' + localStorage.getItem("Token");
			config.headers["userId"] = localStorage.getItem("userId");
			config.headers["content-type"] = "application/x-www-form-urlencoded";
		}

		// console.log("请求的数据:", config);
		return config;
	},
	error => {
		// 对请求错误做些什么
		return Promise.reject("出错", error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	response => {
		// 对响应数据做点什么
		console.log('response', response)
		// 未设置状态码则默认成功状态
		const code = response.data.code || 200;
		// 获取错误信息
		const msg = response.data.msg
		if (code === 401) {
			// 获取当前页面的url
			// var locationUrl = window.location.pathname.split("admin/")[1]
			MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
				confirmButtonText: '重新登录',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				localStorage.clear();
				router.push({
					path:'/login'
				})
			})
		} else if (code === 500) {
			Message({
				message: msg,
				type: 'error'
			})
			return response.data
			// return Promise.reject(new Error(msg))
		} else if (code !== 200) {
			Notification.error({
				title: msg
			})
			return Promise.reject('error')
		} else {

			return response.data
		}
		// return response.data;
	},
	error => {
		// 对响应错误做点什么
		return Promise.reject(error);
	}
);
export default service;