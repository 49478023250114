<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    if (/Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = "http://nl.ybkyl.com/nlpg/#/";
    }
    //  else {
    //   window.location.href = "http://nl.ybkyl.com/";
    // }
  },
};
</script>


<style>
body {
  margin: 0px;
  padding: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
