module.exports = [
	{
	id: 0,
	name: "认知功能",
	key: "cognitive",
	model: "请选择",
	value: -1,
	children: [
		"画钟正确",
		"画钟错误或只回忆出0-1个词",
		"已确诊为认知障碍"
	]
}, {
	id: 1,
	name: "攻击行为",
	key: "aggressiveBehavior",
	model: "请选择",
	value: -1,
	children: [
		"无身体攻击行为和语言攻击行为",
		"每月有几次身体攻击行为或每周有几次语言攻击行为",
		"每周有几次身体攻击行为或每日有语言攻击行为"
	]
}, {
	id: 2,
	name: "抑郁症状",
	key: "depressiveSymptoms",
	model: "请选择",
	value: -1,
	children: [
		"无",
		"情绪低落、不爱说话、不爱梳洗、不爱活动",
		"有自杀念头或自杀行为"
	]
}]
