<template>
  <div class="box">
    <el-form
      ref="elForm"
      size="medium"
      label-width="150px"
      label-position="left"
    >
      <el-col :span="24">
        <h2>老人能力评估表</h2>
      </el-col>
      <el-col :span="24">
        <div class="title">社会参与评估表</div>
      </el-col>
      <div>
        <div v-for="(item, index) in list" :key="index">
          <div class="pitchtitel">{{ item.name }}</div>
          <div
            v-for="(item1, index1) in item.children"
            :key="index1"
            :class="index1 == item.value ? 'pitchon' : 'pitchoff'"
            @click="changepitch(index, index1)"
          >
            {{ item1.name }}
          </div>
          <el-divider></el-divider>
        </div>
      </div>

      <div class="totalpoints">
        <div class="totaltitle">社会参与总分</div>
        <div>{{ allcount }}分</div>
        <div class="totalgrade">上述 5 个项目得分之和</div>
      </div>

      <div class="totalpoints">
        <div class="totaltitle">社会参与分级</div>
        <div>{{ level }}级</div>
        <div class="gradeBox">
          <div class="totalgrade" v-for="(item, index) in grade" :key="index">
            {{ item }}
          </div>
        </div>
      </div>

      <el-form-item size="large" style="text-align: center">
        <el-button type="primary" @click="lastStep">上一步</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button type="primary" @click="savecreateReport">提交评估</el-button>
        <el-button @click="resetForm">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import socialParticipation from "../../js/socialParticipation.js";
import {
  saveSocialParticipation,
  saveEvaluation,
  detail,
  createReport,
} from "@/api/login/login";
export default {
  components: {},
  props: {
    elderlyid: {
      type: [String, Number],
    },
  },
  data() {
    return {
      list: socialParticipation,
      grade: [
        "0 能力完好：总分 0-2 分",
        "1 轻度受损：总分 3-7 分",
        "2 中度受损：总分 8-13 分",
        "3 重度受损：总分 14-20 分",
      ],
      allcount: "",
      level: "",
      oldid: this.elderlyid,
      issave: false, //判断当前是否提交
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.oldid) {
      this.get_detail();
    }
    this.list.forEach((item) => {
      item.value = -1;
    });
  },
  mounted() {},
  methods: {
    // 老人信息详情
    get_detail() {
      let data = new FormData();
      data.append("id", this.oldid);
      detail(data)
        .then((result) => {
          if (result.data.params.socialParticipation) {
            this.allcount = result.data.params.socialParticipation.score;
            this.level = result.data.params.socialParticipation.level;
            this.list[0].value =
              result.data.params.socialParticipation.lifeAbility;
            this.list[1].value =
              result.data.params.socialParticipation.workAbility;
            this.list[2].value =
              result.data.params.socialParticipation.timeSpace;
            this.list[3].value =
              result.data.params.socialParticipation.personalOrientation;
            this.list[4].value =
              result.data.params.socialParticipation.societyAbility;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 保存
    submitForm() {
      // 遍历筛选
      for (let i in this.list) {
        if (this.list[i].value == -1) {
          this.$message({
            message: "请选择" + this.list[i].name,
          });
          return false;
        }
      }
      // 获取选择数据
      let arr = {};
      this.list.forEach((item, index) => {
        arr[item.key] = item.value;
      });
      let data = new FormData();
      data.append("lifeAbility", arr.lifeAbility);
      data.append("workAbility", arr.workAbility);
      data.append("timeSpace", arr.timeSpace);
      data.append("personalOrientation", arr.personalOrientation);
      data.append("societyAbility", arr.societyAbility);
      data.append("infoId", this.oldid);
      data.append("id", this.oldid);
      saveSocialParticipation(data)
        .then((result) => {
          this.allcount = result.data.score;
          this.level = result.data.level;
          this.list[0].value = result.data.lifeAbility;
          this.list[1].value = result.data.workAbility;
          this.list[2].value = result.data.timeSpace;
          this.list[3].value = result.data.personalOrientation;
          this.list[4].value = result.data.societyAbility;

          this.$message({
            message: result.msg,
            type: "success",
          });
          this.issave = true;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    resetForm() {
      // this.$refs["elForm"].resetFields();
      this.list.forEach((item) => {
        item.value = -1;
      });
    },
    // 选择选项
    changepitch(index, index1) {
      this.list[index].value = index1;
    },
    // 提交评估
    savecreateReport() {
      // 遍历筛选
      for (let i in this.list) {
        if (this.list[i].value == -1) {
          this.$message({
            message: "请选择" + this.list[i].name,
          });
          return false;
        }
      }
      // 获取选择数据
      let arr = {};
      this.list.forEach((item, index) => {
        arr[item.key] = item.value;
      });
      let data = new FormData();
      data.append("lifeAbility", arr.lifeAbility);
      data.append("workAbility", arr.workAbility);
      data.append("timeSpace", arr.timeSpace);
      data.append("personalOrientation", arr.personalOrientation);
      data.append("societyAbility", arr.societyAbility);
      data.append("infoId", this.oldid);
      data.append("id", this.oldid);
      saveSocialParticipation(data)
        .then((result) => {
          this.allcount = result.data.score;
          this.level = result.data.level;
          this.list[0].value = result.data.lifeAbility;
          this.list[1].value = result.data.workAbility;
          this.list[2].value = result.data.timeSpace;
          this.list[3].value = result.data.personalOrientation;
          this.list[4].value = result.data.societyAbility;

          // this.$message({
          //   message: result.msg,
          //   type: "success",
          // });
          this.get_createReport();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    get_createReport() {
      let data = new FormData();
      data.append("infoId", this.oldid);
      createReport(data)
        .then((result) => {
          // this.$message({
          //   message: result.msg,
          //   type: "success",
          // });
          this.save();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    //上一步
    lastStep() {
      this.$emit("changefromloist", 3);
    },
    save() {
      let data = new FormData();
      data.append("infoId", this.oldid);
      saveEvaluation(data)
        .then((result) => {
          this.$emit("refer", 4, "数组改变了");
          this.$emit("refer", 5);
          this.$message({
            message: result.msg,
            type: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style  scoped>
div /deep/.el-form-item__content {
  text-align: left;
}

div /deep/.el-form-item--large .el-form-item__content {
  text-align: center;
}
</style>
<style lang="less" scoped>
.box {
  box-sizing: border-box;
  padding: 30px 100px;
}
h2 {
  text-align: left;
  padding-left: 1vw;
}
.title {
  background: #3276ff;
  color: #fff;
  height: 8vh;
  line-height: 8vh;
  margin-bottom: 2vh;
}
.pitchtitel {
  color: #434343;
  font-size: 1.3vw;
  font-weight: bold;
}
.pitchon {
  // border: 1px solid #3276ff;
  background: #7aa6ff;
  color: #fff;
  width: 80%;
  margin: 0.5vw auto;
  border-radius: 5px;
  padding: 1vw 0;
  cursor: pointer;
}
.pitchoff {
  border: 2px solid #dedede;
  border-radius: 5px;
  width: 80%;
  margin: 0.5vw auto;
  padding: 1vw 0;
  cursor: pointer;
}
.totalpoints {
  border: 2px solid #7aa6ff;
  border-radius: 5px;
  width: 80%;
  padding: 1vw 0;
  margin: 0.5vw auto;
}
.totaltitle {
  color: #7aa6ff;
  font-size: 1.1vw;
}
.totalgrade {
  white-space: nowrap;
  color: #fd221f;
  padding: 0 0.8vw;
}
.totalgrade2 {
  //   white-space: nowrap;
  text-align: left;
  color: #fd221f;
  padding: 0 0.8vw;
}
.gradeBox {
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
</style>
