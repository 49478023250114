<!--  -->
<template>
  <div>
    <header>
      <div class="top">
        <div class="homepage" @click="go_officialwebsite">首页</div>
        <el-dropdown>
          <img src="../../static/images/headerlogo.png" alt="" />
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <img src="../../static/images/header.png" alt="" class="logo" />
      <div class="logoname">{{ userName }}</div>
      <div class="id">ID：{{ userId }}</div>
    </header>
    <div class="beginAssess">
      <div class="beginbtn" @click="beginassess">开始评估</div>
    </div>

    <div class="main">
      <div v-for="(item, index) in list" :key="index" class="oldman">
        <div class="contenone">
          <div class="surname">{{ item.name.charAt(0) }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="time">{{ item.evaluationDate }}</div>
          <div class="state" v-if="item.status == 1">未完成</div>
          <div class="state2" v-if="item.status == 2">已完成</div>
        </div>
        <div class="contentwo">
          <div class="label">联系方式</div>
          <div class="matter">{{ item.phone2 }}</div>
          <div class="label sex">性别</div>
          <div class="matter">{{ item.gender == 0 ? "男" : "女" }}</div>
        </div>
        <div class="contentwo">
          <div class="label">身份证号码</div>
          <div class="matter">
            {{ item.card.replace(/(\d{3})\d*(\d{4})/, "$1****$2") }}
          </div>
        </div>
        <div class="contentwo">
          <div class="label">所在区划</div>
          <div class="matter">{{ item.areaName }}</div>
        </div>
        <div class="contentwo">
          <div class="label">详细地址</div>
          <div class="matter">{{ item.fulladdress }}</div>
        </div>
        <div class="contentwo">
          <div class="label">评估等级</div>
          <div class="matter">
            {{
              item.evaluation == null
                ? "未完成"
                : item.evaluation.finalResult == 0
                ? "正常"
                : item.evaluation.finalResult == 1
                ? "轻度"
                : item.evaluation.finalResult == 2
                ? "中度"
                : "重度"
            }}
          </div>
        </div>
        <div class="grade" v-if="item.evaluation">
          <img
            src="../../static/images/good.png"
            v-if="item.evaluation.finalResult == 0"
            alt=""
          />
          <img
            src="../../static/images/mild.png"
            v-if="item.evaluation.finalResult == 1"
            alt=""
          />
          <img
            src="../../static/images/moderate.png"
            v-if="item.evaluation.finalResult == 2"
            alt=""
          />
          <img
            src="../../static/images/severe.png"
            v-if="item.evaluation.finalResult == 3"
            alt=""
          />
        </div>
        <div
          class="Continue"
          v-if="item.status == 1"
          @click="continueAssess(item.id)"
        >
          继续评估
        </div>
        <div
          class="report"
          v-if="item.status == 2"
          @click="finishreport(item.card, item.id)"
        >
          查看报告
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { list } from "@/api/login/login";
export default {
  data() {
    return {
      list: "",
      userName: "",
      userId: "",
      searchform: {
        pageNum: 1,
        length: 8,
      },
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.userName = localStorage.getItem("userName");
    this.userId = localStorage.getItem("userId");
    this.get_list();
  },
  methods: {
    beginassess() {
      this.$router.push({
        path: "/adminHome",
      });
    },
    // 去首页
    go_officialwebsite() {
      this.$router.push({
        name: "officialwebsite",
      });
    },
    // 退出
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({ path: this.redirect || "/login" }).catch(() => {});
      });
    },
    // 获取评估人员列表
    get_list() {
      let data = new FormData();
      data.append("pageNum", this.searchform.pageNum);
      data.append("length", this.searchform.length);
      list(data)
        .then((result) => {
          this.list = result.data.rows;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 继续评估
    continueAssess(e) {
      this.$router.push({
        path: "/adminHome",
        query: {
          id: e,
        },
      });
    },

    finishreport(card, id) {
      this.$router.push({
        path: "/serveReport",
        query: {
          card: card,
          id: id,
        },
      });
    },
  },
};
</script>
<style  scoped>
.grade {
  position: absolute;
  right: 2vw;
  bottom: 10vh;
}
header {
  width: 100%;
  height: 40vh;
  background-image: url("../../static/images/headerbg.png");
}
.top {
  padding: 30px 0;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.top .homepage {
  margin-left: 85%;
  margin-right: 1%;
  cursor: pointer;
}

.logo {
  border-radius: 50%;
  background: #fff;
  width: 6vw;
  height: 6vw;
  border: 8px solid #fff;
  margin-bottom: 1.2vw;
}
.logoname {
  font-size: 1.6vw;
  color: #fff;
  margin-bottom: 10px;
  cursor: pointer;
}
.id {
  font-size: 1.1vw;
  color: #fff;
  cursor: pointer;
}
.beginAssess {
  padding: 30px 0;
  background: #f8f8f8;
}
.beginbtn {
  color: #fff;
  font-size: 1.5vw;
  border-radius: 5px;
  text-align: center;
  line-height: 70px;
  background: #3377ff;
  height: 70px;
  width: 12vw;
  margin: 0 auto;
  cursor: pointer;
}
.main {
  width: 100%;
  min-height: calc(100vh - 40vh - 130px);
  background: #f0f0f0;
  padding: 0.1px;
}
.oldman {
  width: 72vw;
  padding: 30px;
  background-repeat: no-repeat;
  margin: 30px auto;
  position: relative;
  /* height: 50vh; */
  background-size: 100% 100%;
  background-image: url("../../static/images/orthogon.png");
}
.contenone {
  display: flex;
  margin: 6px 0 30px;
  align-items: center;
}
.surname {
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3377ff;
  border-radius: 50%;
  font-size: 30px;
}
.name {
  color: #3377ff;
  font-size: 1.3vw;
  margin-left: 10px;
}
.time {
  font-size: 1.1vw;
  margin-left: 1.1vw;
  color: #9b9b9b;
}
.state {
  color: #ff6d33;
  margin-left: auto;
  font-size: 1.1vw;
}
.state2 {
  color: #3377ff;
  margin-left: auto;
  font-size: 1.1vw;
}
.contentwo {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.label {
  width: 7vw;
  font-size: 1.1vw;
  font-weight: bold;
  text-align: right;
  color: #414141;
}
.sex {
  /* margin-left: 8%; */
}
.matter {
  width: 20vw;
  font-size: 1.1vw;
  text-align: left;
  margin-left: 1.1vw;
  color: #9b9b9b;
}
.Continue {
  width: 6vw;
  height: 5vh;
  color: #fff;
  font-size: 1.1vw;
  position: absolute;
  right: 40px;
  line-height: 5vh;
  bottom: 40px;
  background-color: #ff6d32;
  border-radius: 5px;
  cursor: pointer;
}
.report {
  width: 6vw;
  height: 5vh;
  color: #fff;
  font-size: 1.1vw;
  position: absolute;
  right: 40px;
  line-height: 5vh;
  bottom: 40px;
  background-color: #3377ff;
  border-radius: 5px;
  cursor: pointer;
}
</style>