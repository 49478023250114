//引入request.js文件
import request from "../request.js";

// 登录
export function login(data) {
  return request({
    url: "h5/user/assess/login", //这个地址是去掉公共地址和私有域名之后剩下的地址
    method: "post", //请求方式 支持多种方式  get post put delete 等等
    data //发送请求要配置的参数 无参数的情况下也可以不写
  });
}


// 评估列表
export function list(data) {
  return request({
    url: "/h5/ability/assess/list",
    method: "post",
    data
  });
}
//提交基本信息
export function saveBasicInfo(data) {
  return request({
    url: "h5/ability/assess/saveBasicInfo",
    method: "post",
    data
  });
}
// 老人详情信息接口
export function detail(data) {
  return request({
    url: "h5/ability/assess/detail",
    method: "post",
    data
  });
}



// 日常生活
export function saveDailyActivity(data) {
  return request({
    url: "h5/ability/assess/saveDailyActivity",
    method: "post",
    data
  });
}

// 精神
export function saveMental(data) {
  return request({
    url: "h5/ability/assess/saveMental",
    method: "post",
    data
  });
}
// 感知
export function savePerceptionCommunication(data) {
  return request({
    url: "h5/ability/assess/savePerceptionCommunication",
    method: "post",
    data
  });
}

// 社会
export function saveSocialParticipation(data) {
  return request({
    url: "h5/ability/assess/saveSocialParticipation",
    method: "post",
    data
  });
}


// 单独评估报告
export function assessmentReport(data) {
  return request({
    // url: 'api/assess/elderly/grade',
    url:'prod-api/api/assess/elderly/report',
    method: 'post',
    data:data
  })
}
// 等级保存
export function createReport(data) {
  return request({
    url: 'h5/ability/assess/createReport',
    method: 'post',
    data: data
  })
}


// 提交
export function saveEvaluation(data) {
  return request({
    url: 'h5/ability/assess/saveEvaluation',
    method: 'post',
    data: data
  })
}
// 身份证信息查询
export function getBasicInfo(data) {
  return request({
    url: 'h5/ability/assess/getBasicInfo',
    method: 'post',
    data: data
  })
}
//发送验证码
export function sendCode(data) {
  return request({
    url: 'api/code/sendCode',
    method: 'post',
    data: data
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

// 获取区划
export function treeList(query) {
  return request({
    // url: '/h5/ability/assess/area/treeList',
    url:'/system/area/list',
    method: 'get',
    // data: data
    params: query
  })
}

