<template>
  <div class="box">
    <div class="top">
      <div class="homepage" @click="go_officialwebsite">首页</div>
      <el-dropdown>
        <img src="../../static/images/headerlogo.png" alt="" />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="progressbarBox">
      <div class="progressBar">
        <div v-for="(item, index) in list" :key="index" class="progress">
          <img
            :src="item.iamge"
            alt=""
            v-if="item.show == true && item.ling == 0"
          />
          <img
            :src="item.iamges"
            alt=""
            v-if="item.show == false && item.ling == 0"
          />
          <div v-if="item.ling == 1 && item.show == true" class="line"></div>
          <div v-if="item.ling == 1 && item.show == false" class="line2"></div>
        </div>
      </div>
      <div class="title">
        <div v-for="(item, index) in titlelist" :key="index" class="titlebox">
          <div :class="item.show == true ? 'name' : 'name1'">
            {{ item.name }}
          </div>
          <div :class="item.show == true ? 'conten' : 'conten1'">
            {{ item.conten }}
          </div>
        </div>
      </div>
    </div>
    <add-elderassessment
      @changeData="changeData"
      @oldid="oldid"
      v-if="showindex == 0"
      :elderlyid="elderid"
    >
    </add-elderassessment>
    <!-- 评估实施 -->
    <div v-if="showindex == 1">
      <div class="typeBox">
        <div
          v-for="(item, index) in typelist"
          :key="index"
          :class="item.disable == true ? 'type' : 'types'"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 基本信息表 -->
      <basic-information
        v-if="typeindex == 0"
        @changefromloist="changefromloist"
        @lastStep="lastStep"
        :elderlyid="elderid"
      ></basic-information>
      <!-- 日常生活活动评估表 -->
      <daillyliving-activitiesassessment
        v-if="typeindex == 1"
        :elderlyid="elderid"
        @changefromloist="changefromloist"
      ></daillyliving-activitiesassessment>
      <!-- 精神状态评估表 -->
      <mental-state
        v-if="typeindex == 2"
        :elderlyid="elderid"
        @changefromloist="changefromloist"
      ></mental-state>
      <perception-communication
        v-if="typeindex == 3"
        :elderlyid="elderid"
        @changefromloist="changefromloist"
      ></perception-communication>
      <socialParticipation
        v-if="typeindex == 4"
        :elderlyid="elderid"
        @changefromloist="changefromloist"
        @refer="refer"
      ></socialParticipation>
    </div>
    <!-- 报告 -->
    <div v-if="showindex == 2">
      <report :elderlyid="elderid"></report>
    </div>
  </div>
</template>

<script>
import addElderassessment from "./server/addElderassessment.vue";
import basicInformation from "./server/basicInformation.vue";
import daillylivingActivitiesassessment from "./server/daillylivingActivitiesassessment.vue";
import mentalState from "./server/mentalState.vue";
import perceptionCommunication from "./server/perceptionCommunication.vue";
import socialParticipation from "./server/socialParticipation.vue";
import report from "./server/report.vue";
import { detail } from "@/api/login/login";
export default {
  components: {
    addElderassessment,
    basicInformation,
    daillylivingActivitiesassessment,
    mentalState,
    perceptionCommunication,
    socialParticipation,
    report,
  },
  data() {
    return {
      index: 1,
      list: [
        {
          iamge: require("../../static/images/finishone.png"),
          iamges: require("../../static/images/finishone.png"),
          show: true,
          ling: 0,
        },
        {
          show: true,
          ling: 1,
        },
        {
          iamges: require("../../static/images/gray.png"),
          iamge: require("../../static/images/finishtwo.png"),
          show: false,
          ling: 0,
        },
        {
          show: false,
          ling: 1,
        },
        {
          iamges: require("../../static/images/graythree.png"),
          iamge: require("../../static/images/finishthree.png"),
          show: false,
          ling: 0,
        },
      ],

      titlelist: [
        {
          name: "信息录入",
          conten: "老年人能力评估基本信息表录入",
          show: true,
          ling: 0,
        },
        {
          name: "评估实施",
          conten: "老年人能力评估实施",
          show: false,
          ling: 0,
        },
        {
          name: "保存报告",
          conten: "老年人能力评估报告签名、保存和打印",
          show: false,
          ling: 0,
        },
      ],
      // 展示第几步的下标
      showindex: 0,

      typelist: [
        {
          name: "基本信息表  ",
          disable: true,
        },
        {
          name: "日常生活活动评估表  ",
          disable: false,
        },
        {
          name: "精神状态评估表  ",
          disable: false,
        },
        {
          name: "感知觉与沟通评估表  ",
          disable: false,
        },
        {
          name: "社会参与评估表  ",
          disable: false,
        },
      ],
      typeindex: 0,
      elderid: "",
      byvalue: "",
    };
  },
  created() {
    this.elderid = this.$route.query.id;
    if (this.elderid) {
      this.get_detail();
    }
  },

  computed: {},

  // mounted: {},

  methods: {
    lastStep() {
      this.showindex = 0;
      window.scrollTo(0,0)
    },
    // 去首页
    go_officialwebsite() {
      this.$router.push({
        name: "officialwebsite",
      });
    },
    // 接受子组件得值
    changeData(data, tips) {
      this.list[2].show = true;
      this.list[3].show = true;
      this.titlelist[1].show = true;
      this.showindex = 1;
    },
    oldid(e) {
      this.elderid = e;
    },
    // 退出
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        localStorage.clear();
        this.$router.push({ path: this.redirect || "/login" }).catch(() => {});
      });
    },
    // 接受子组件的值
    updatetype(data) {
      this.typeindex = data;
    },
    //接收社会参与评估表
    refer(e) {
      this.list[4].show = true;
      this.titlelist[2].show = true;
      this.showindex = 2;
    },
    changefromloist(e) {
      this.typeindex = e;
      this.typelist[e].disable = true;
      window.scrollTo(0,0)
    },
    // 获取详情信息
    get_detail() {
      let data = new FormData();
      data.append("id", this.elderid);
      detail(data)
        .then((result) => {
          this.byvalue = result.data;
          if (result.data.params) {
            if (result.data.provideOldman) {
              this.typelist[0].disable = true;
            }
            if (result.data.params.dailyActivity) {
              this.typelist[1].disable = true;
            }
            if (result.data.params.mental) {
              this.typelist[2].disable = true;
            }
            if (result.data.params.perceptionCommunication) {
              this.typelist[3].disable = true;
            }
            if (result.data.params.socialParticipation) {
              this.typelist[4].disable = true;
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>

<style lang='less' scoped>
.progressbarBox {
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;
}
.progressBar {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  padding-bottom: 0px;
  cursor: pointer;

  .progress {
    display: flex;
    align-items: center;
  }
  .name {
    font-size: 30px;
    color: #7e8bf3;
  }
  .name1 {
    font-size: 30px;
    color: #66647a;
  }
  .conten {
    font-size: 20px;
    color: #7e8bf3;
  }
  .conten1 {
    font-size: 20px;
    color: #dbdbdb;
  }
  .line {
    margin: 0 10px;
    height: 7px;
    width: 15vw;
    border-radius: 3px;
    background: #7f97f3;
  }
  .line2 {
    margin: 0 10px;
    height: 7px;
    width: 15vw;
    border-radius: 3px;
    background: #dbdbdb;
  }
}
/* 屏幕小于1440px */
@media screen and (max-width: 1440px) {
  .title {
    display: flex;
    justify-content: center;
    // width: 100vw;
    box-sizing: border-box;
    cursor: pointer;
    .titlebox {
      width: 24vw;

      .name {
        font-size: 30px;
        color: #7e8bf3;
      }
      .name1 {
        font-size: 30px;
        color: #66647a;
      }
      .conten {
        font-size: 20px;
        color: #7e8bf3;
      }
      .conten1 {
        font-size: 20px;
        color: #dbdbdb;
      }
    }
  }
}

/* 屏幕等于1440px */
@media screen and (max-width: 1440px) and (min-width: 1440px) {
  .title {
    display: flex;
    justify-content: center;
    // width: 100vw;
    cursor: pointer;
    box-sizing: border-box;
    .titlebox {
      width: 23vw;

      .name {
        font-size: 30px;
        color: #7e8bf3;
      }
      .name1 {
        font-size: 30px;
        color: #66647a;
      }
      .conten {
        font-size: 20px;
        color: #7e8bf3;
      }
      .conten1 {
        font-size: 20px;
        color: #dbdbdb;
      }
    }
  }
}

/* 屏幕大于1440px */
@media screen and (min-width: 1441px) {
  .title {
    display: flex;
    cursor: pointer;
    justify-content: center;
    // width: 100vw;
    box-sizing: border-box;
    .titlebox {
      width: 21vw;

      .name {
        font-size: 30px;
        color: #7e8bf3;
      }
      .name1 {
        font-size: 30px;
        color: #66647a;
      }
      .conten {
        font-size: 20px;
        color: #7e8bf3;
      }
      .conten1 {
        font-size: 20px;
        color: #dbdbdb;
      }
    }
  }
}

// .title {
//   display: flex;
//   justify-content: center;
//   // width: 100vw;
//   box-sizing: border-box;
//   .titlebox {
//     width: 21%;

//     .name {
//       font-size: 30px;
//       color: #7e8bf3;
//     }
//     .name1 {
//       font-size: 30px;
//       color: #66647a;
//     }
//     .conten {
//       font-size: 20px;
//       color: #7e8bf3;
//     }
//     .conten1 {
//       font-size: 20px;
//       color: #dbdbdb;
//     }
//   }
// }
.top {
  padding: 30px 0;
  display: flex;
  align-items: center;
  color: #fff;
  background: #3174ff;
  cursor: pointer;
}
.top .homepage {
  margin-left: 85%;
  margin-right: 1%;
  cursor: pointer;
}
.top img {
  margin-left: 1%;
}
.typeBox {
  display: flex;
  justify-content: center;
  padding: 1.5vw 0;
  .type {
    padding: 1vw 2vw;
    margin: 0 0.5vw;
    background: #3276ff;
    color: #fff;
    cursor: pointer;
  }
  .types {
    padding: 1vw 2vw;
    margin: 0 0.5vw;
    background: #e8e8e8;
    color: #000;
    cursor: pointer;
  }
}
</style>