<template>
  <div class="box">
    <el-form
      ref="elForm"
      size="medium"
      label-width="150px"
      label-position="left"
    >
      <el-col :span="24">
        <h2>老人能力评估表</h2>
      </el-col>
      <el-col :span="24">
        <div class="title">精神状态评估表</div>
      </el-col>
      <div>
        <div v-for="(item, index) in list" :key="index">
          <div class="pitchtitel">{{ item.name }}</div>
          <div
            v-for="(item1, index1) in item.children"
            :key="index1"
            :class="index1 == item.value ? 'pitchon' : 'pitchoff'"
            @click="changepitch(index, index1)"
          >
            {{ item1 }}
          </div>
          <el-divider></el-divider>
        </div>
      </div>

      <div class="totalpoints">
        <div class="totaltitle">精神状态总分</div>
        <div>{{ allcount }}分</div>
        <div class="totalgrade">上述 3 个项目得分之和</div>
      </div>

      <div class="totalpoints">
        <div class="totaltitle">精神状态分级</div>
        <div>{{ level }}级</div>
        <div class="gradeBox">
          <div class="totalgrade" v-for="(item, index) in grade" :key="index">
            {{ item }}
          </div>
        </div>
      </div>

      <el-form-item size="large" style="text-align: center">
        <el-button type="primary" @click="lastStep">上一步</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button type="primary" @click="submitForm">下一步</el-button>
        <el-button @click="resetForm">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import richang from "../../js/jshen.js";
import { saveMental, detail } from "@/api/login/login";
export default {
  components: {},
  props: {
    elderlyid: {
      type: [String, Number],
    },
  },
  data() {
    return {
      list: richang,
      grade: [
        "0 能力完好：总分 0 分",
        "1 轻度受损：总分 1 分",
        "2 中度受损：总分 2-3 分",
        "3 重度受损：总分 4-6 分",
      ],
      oldid: this.elderlyid,
      allcount: "",
      level: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    if (this.oldid) {
      this.get_detail();
    }
    this.list.forEach((item) => {
      item.value == -1;
    });
  },
  mounted() {},
  methods: {
    submitForm() {
      // 遍历筛选
      for (let i in this.list) {
        if (this.list[i].value == -1) {
          this.$message({
            message: "请选择" + this.list[i].name,
            // type: "warning",
          });
          return false;
        }
      }
      // 获取选择数据
      let arr = {};
      this.list.forEach((item, index) => {
        arr[item.key] = item.value;
      });
      let data = new FormData();
      data.append("cognitive", arr.cognitive);
      data.append("aggressiveBehavior", arr.aggressiveBehavior);
      data.append("depressiveSymptoms", arr.depressiveSymptoms);
      data.append("infoId", this.oldid);
      data.append("id", this.oldid);
      saveMental(data)
        .then((result) => {
          this.$emit("changefromloist", 3, "数组改变了");
          this.$message({
            message: result.msg,
            type: "success",
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
      this.list.forEach((item) => {
      item.value = -1;
    });
    },

    // 选择选项
    changepitch(index, index1) {
      this.list[index].value = index1;
    },
     //上一步
    lastStep(){
        this.$emit("changefromloist", 1);
    },
    // 老人信息详情
    get_detail() {
      let data = new FormData();
      data.append("id", this.oldid);
      detail(data)
        .then((result) => {
          if (result.data.params.mental) {
            this.allcount = result.data.params.mental.score;
            this.level = result.data.params.mental.level;
            this.list[0].value = result.data.params.mental.cognitive;
            this.list[1].value = result.data.params.mental.aggressiveBehavior;
            this.list[2].value = result.data.params.mental.depressiveSymptoms;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style  scoped>
div /deep/.el-form-item__content {
  text-align: left;
}

div /deep/.el-form-item--large .el-form-item__content {
  text-align: center;
}
</style>
<style lang="less" scoped>
.box {
  // width: 100%;
  box-sizing: border-box;
  padding: 30px 100px;
}
h2 {
  text-align: left;
  padding-left: 1vw;
}
.title {
  background: #3276ff;
  color: #fff;
  height: 8vh;
  line-height: 8vh;
  margin-bottom: 2vh;
}
.pitchtitel {
  color: #434343;
  font-size: 1.3vw;
  font-weight: bold;
}
.pitchon {
  // border: 1px solid #3276ff;
  background: #7aa6ff;
  color: #fff;
  width: 80%;
  margin: 0.5vw auto;
  border-radius: 5px;
  padding: 1vw 0;
  cursor: pointer;
}
.pitchoff {
  border: 2px solid #dedede;
  border-radius: 5px;
  width: 80%;
  margin: 0.5vw auto;
  padding: 1vw 0;
  cursor: pointer;
}
.totalpoints {
  border: 2px solid #7aa6ff;
  border-radius: 5px;
  width: 80%;
  padding: 1vw 0;
  margin: 0.5vw auto;
}
.totaltitle {
  color: #7aa6ff;
  font-size: 1.1vw;
}
.totalgrade {
  white-space: nowrap;
  color: #fd221f;
  padding: 0 0.8vw;
}
.gradeBox {
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
</style>
