module.exports = [
	{
	id: 0,
	name: "生活能力",
	key: "lifeAbility",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"除个人生活自理外能料理家务或当家管理事务",
			isdiaable:false
		},
		{
			name:"能做家务但欠好，家庭事务安排欠条理",
			isdiaable:false
		},
		{
			name:"只有在他人帮助下才能做些家务，但质量不好",
			isdiaable:false
		},
		{
			name:"个人基本生活事务能自理，在督促下可洗漱",
			isdiaable:false
		},
		{
			name:"基本生活事务需要部分帮助或完全依赖他人帮助",
			isdiaable:false
		},
	]
}, {
	id: 1,
	name: "工作能力",
	key: "workAbility",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"工作可照常进行",
			isdiaable:false
		},
		{
			name:"工作能力有所下降",
			isdiaable:false
		},
		{
			name:"明显不如以往，部分遗忘",
			isdiaable:false
		},
		{
			name:"熟练工作片段保留，技能全部遗忘",
			isdiaable:false
		},
		{
			name:"全部遗忘",
			isdiaable:false
		},
	]
}, {
	id: 2,
	name: "时间/空间定向",
	key: "timeSpace",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"时间观念清楚，可单独出远门，能很快掌握新环境的方位",
			isdiaable:false
		},
		{
			name:"时间观念有些下降，不知回家路线",
			isdiaable:false
		},
		{
			name:"时间观念较差，不知道方位",
			isdiaable:false
		},
		{
			name:"时间观念很差，对现住地不知名称和方位",
			isdiaable:false
		},
		{
			name:"无时间观念",
			isdiaable:false
		},
		// "时间观念清楚，可单独出远门，能很快掌握新环境的方位",
		// "时间观念有些下降，不知回家路线",
		// "时间观念较差，不知道方位",
		// "时间观念很差，对现住地不知名称和方位",
		// "无时间观念"
	]
}, {
	id: 3,
	name: "人物定向",
	key: "personalOrientation",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"知道周围人们的关系，可用适当称呼",
			isdiaable:false
		},
		{
			name:"只知家中亲密近亲的关系",
			isdiaable:false
		},
		{
			name:"只能称呼家中人，不知其关系",
			isdiaable:false
		},
		{
			name:"只认识常同住的亲人，可辨熟人和生人",
			isdiaable:false
		},
		{
			name:"只认识保护人",
			isdiaable:false
		},
		
		// "知道周围人们的关系，可用适当称呼",
		// "只知家中亲密近亲的关系",
		// "只能称呼家中人，不知其关系",
		// "只认识常同住的亲人，可辨熟人和生人",
		// "只认识保护人"
	]
}, {
	id: 4,
	name: "社会交往能力",
	key: "societyAbility",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"参与社会，在社会环境有一定的适应能力",
			isdiaable:false
		},
		{
			name:"能适应单纯环境，主动接触人，不能理解隐喻语",
			isdiaable:false
		},
		{
			name:"脱离社会，不会主动待人，容易上当受骗",
			isdiaable:false
		},
		{
			name:"谈吐内容不清楚，表情不恰当",
			isdiaable:false
		},
		{
			name:"难以与人接触",
			isdiaable:false
		},
		// "参与社会，在社会环境有一定的适应能力",
		// "能适应单纯环境，主动接触人，不能理解隐喻语",
		// "脱离社会，不会主动待人，容易上当受骗",
		// "谈吐内容不清楚，表情不恰当",
		// "难以与人接触"
	]
}]
