module.exports = [{
	id: 0,
	name: "进食",
	key: "eat",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立进食",
			isdiaable:false
		},
		{
			name:"需部分帮助",
			isdiaable:false
		},
		{
			name:"需极大帮助、完全依赖他人或留置营养管",
			isdiaable:false
		}	
	]
}, {
	id: 1,
	name: "洗澡",
	key: "bathe",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立完成",
			isdiaable:false
		},
		{
			name:"需他人帮助",
			isdiaable:false
		},
		
		
	]
}, {
	id: 2,
	name: "修饰",
	key: "beautify",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立完成",
			isdiaable:false
		},
		{
			name:"需他人帮助",
			isdiaable:false
		},
	]
}, {
	id: 3,
	name: "穿衣",
	key: "dress",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立完成",
			isdiaable:false
		},
		{
			name:"需他人帮助",
			isdiaable:false
		},
		{
			name:"需极大帮助或完全依赖他人",
			isdiaable:false
		},
	]
}, {
	id: 4,
	name: "大便控制",
	key: "stoolControl",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可控制大便",
			isdiaable:false
		},
		{
			name:"偶尔失控或需要他人提示",
			isdiaable:false
		},
		{
			name:"完全失控",
			isdiaable:false
		},		
	]
}, {
	id: 5,
	name: "小便控制",
	key: "urinationControl",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可控制小便",
			isdiaable:false
		},
		{
			name:"偶尔失控或需要他人提示",
			isdiaable:false
		},
		{
			name:"完全失控或留置导尿管",
			isdiaable:false
		},
	]
}, {
	id: 6,
	name: "如厕",
	key: "toilet",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立完成",
			isdiaable:false
		},
		{
			name:"需部分帮助",
			isdiaable:false
		},
		{
			name:"需极大帮助或完全依赖他人",
			isdiaable:false
		},
	]
}, {
	id: 7,
	name: "床椅转移",
	key: "bedChairTransfer",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立完成",
			isdiaable:false
		},
		{
			name:"需部分帮助",
			isdiaable:false
		},
		{
			name:"需极大帮助",
			isdiaable:false
		},
		{
			name:"完全依赖他人",
			isdiaable:false
		},
		
	]
}, {
	id: 8,
	name: "平地行走",
	key: "levelWalking",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立在平地上行走45m",
			isdiaable:false
		},
		{
			name:"需部分帮助",
			isdiaable:false
		},
		{
			name:"需极大帮助",
			isdiaable:false
		},
		{
			name:"完全依赖他人",
			isdiaable:false
		},
	]
}, {
	id: 9,
	name: "上下楼梯",
	key: "stairActivity",
	model: "请选择",
	value: -1,
	children: [
		{
			name:"可独立上下楼梯",
			isdiaable:false
		},
		{
			name:"需部分帮助",
			isdiaable:false
		},
		{
			name:"需极大帮助或完全依赖他人",
			isdiaable:false
		},
	]
}]
