import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Officialwebsite from '../views/officialwebsite.vue';
import Admin from '../views/admin.vue';
import Login from '../views/login.vue';
import ServeReport from '../views/serveReport.vue';
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'officialwebsite',
    component: Officialwebsite
  },
  {
    path: '/addElderassessment',
    name: 'AddElderassessment',
    component: (resolve) => require(['@/views/server/addElderassessment'], resolve),
  },
  {
    path: '/home',
    name: 'Home',
    component: (resolve) => require(['@/views/Home'], resolve),

  },
  {
    path: '/login',
    name: 'Login',
    component: (resolve) => require(['@/views/login'], resolve),

  },
  {
    path: '/adminHome',
    name: 'AdminHome',
    component: (resolve) => require(['@/views/admin'], resolve),
  },
  {
    path: '/serveReport',
    name: 'ServeReport',
    component: (resolve) => require(['@/views/serveReport'], resolve),
  }


  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router