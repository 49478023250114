//导航守卫
import router from "./router/index";
const whiteList = ['/login']
router.beforeEach((to,from,next)=>{
    const hasToken=localStorage.getItem('Token')
    // console.log('hasToken',hasToken)
    if(hasToken){
        if(to.path==="/login"){
            next((false))
        }else{
            next()
        }
    }else{
        if(to.path==="/login" || to.path ==="/"){
            next()
        }else{
            next("/login")
        }
    }
})
