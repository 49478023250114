<template>
  <div class="box">
    <el-form
      ref="elForm"
      :model="formData"
      :rules="rules"
      size="medium"
      label-width="150px"
      label-position="left"
    >
      <el-col :span="24">
        <h2>老人能力评估基本信息表</h2>
      </el-col>
      <el-col :span="24">
        <div class="title">基本信息表</div>
      </el-col>
      <el-form-item label="评估编号">
        <div class="">{{ number }}</div>
      </el-form-item>
      <el-form-item label="评估基准日期">
        <div class="">{{ evaluationDate }}</div>
      </el-form-item>
      <el-form-item label="姓名">
        <div class="">{{ userInfo.name }}</div>
      </el-form-item>
      <el-form-item label="性别">
        <div class="">{{ userInfo.gender == 0 ? "男" : "女" }}</div>
      </el-form-item>
      <el-form-item label="出生日期">
        <div class="">{{ userInfo.birthDate }}</div>
      </el-form-item>
      <el-form-item label="身份证号">
        <div class="">{{ userInfo.card }}</div>
      </el-form-item>
      <el-form-item label="所在区划">
        <div class="">{{ userInfo.areaName }}</div>
      </el-form-item>
      <el-form-item label="详细地址">
        <div class="">{{ userInfo.fulladdress }}</div>
      </el-form-item>
      <el-form-item label="移动电话">
        <div class="">{{ userInfo.phone2 }}</div>
      </el-form-item>
      <el-form-item label="固话">
        <div class="">{{ userInfo.phone1 }}</div>
      </el-form-item>
      <el-divider></el-divider>

      <el-form-item label="评估原因" prop="evaluatecause">
        <el-radio-group v-model="formData.evaluatecause" size="medium">
          <el-radio
            v-for="(item, index) in evaluatecauseOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="民族" prop="nation">
        <el-radio-group v-model="formData.nation" size="medium">
          <el-radio
            v-for="(item, index) in nationOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="文化程度" prop="education">
        <el-radio-group v-model="formData.education" size="medium">
          <el-radio
            v-for="(item, index) in educationOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="婚姻状况" prop="marriage">
        <el-radio-group v-model="formData.marriage" size="medium">
          <el-radio
            v-for="(item, index) in marriageOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="居住状况" prop="live">
        <el-radio-group v-model="formData.live" size="medium">
          <el-radio
            v-for="(item, index) in liveOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="经济来源" prop="moneySource">
        <el-checkbox-group v-model="formData.moneySource" size="medium">
          <el-checkbox
            v-for="(item, index) in moneySourceOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="疾病诊断-痴呆" prop="dementia">
        <el-radio-group v-model="formData.dementia" size="medium">
          <el-radio
            v-for="(item, index) in dementiaOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="疾病诊断-精神疾病" prop="spirit">
        <el-radio-group v-model="formData.spirit" size="medium">
          <el-radio
            v-for="(item, index) in spiritOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="疾病诊断-慢性疾病" prop="chronic">
        <el-input
          v-model="formData.chronic"
          placeholder="请输入疾病诊断-慢性疾病"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="近30天内意外事件-跌倒" prop="fall">
        <el-radio-group v-model="formData.fall" size="medium">
          <el-radio
            v-for="(item, index) in fallOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="近30天内意外事件-走失" prop="lost">
        <el-radio-group v-model="formData.lost" size="medium">
          <el-radio
            v-for="(item, index) in lostOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="近30天内意外事件-噎食" prop="blocking">
        <el-radio-group v-model="formData.blocking" size="medium">
          <el-radio
            v-for="(item, index) in blockingOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="近30天内意外事件-自杀" prop="suicide">
        <el-radio-group v-model="formData.suicide" size="medium">
          <el-radio
            v-for="(item, index) in suicideOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="近30天内意外事件-其他" prop="other">
        <el-input
          v-model="formData.other"
          placeholder="请输入近30天内意外事件-其他"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="信息提供者的姓名" prop="provideName">
        <el-input
          v-model="formData.provideName"
          placeholder="请输入信息提供者的姓名"
          clearable
          :style="{ width: '100%' }"
        >
        </el-input>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="信息提供者与老人的关系" prop="provideOldman">
        <el-radio-group v-model="formData.provideOldman" size="medium">
          <el-radio
            v-for="(item, index) in provideOldmanOptions"
            :key="index"
            :label="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item size="large" style="text-align: center">
        <el-button type="primary" @click="lastStep">上一步</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button type="primary" @click="submitForm">下一步</el-button>
        <el-button @click="resetForm">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  evaluatecauseOptions,
  nationOptions,
  educationOptions,
  marriageOptions,
  liveOptions,
  moneySourceOptions,
  dementiaOptions,
  spiritOptions,
  fallOptions,
  lostOptions,
  blockingOptions,
  provideOldmanOptions,
  suicideOptions,
} from "@/utils/auth";
import { saveBasicInfo, detail } from "@/api/login/login";
export default {
  components: {},
  props: {
    elderlyid: {
      type: [String, Number],
    },
  },
  data() {
    return {
      formData: {
        evaluatecause: "",
        nation: "",
        education: "",
        marriage: "",
        live: "",
        moneySource: [],
        dementia: "",
        spirit: "",
        chronic: "",
        fall: "",
        lost: "",
        blocking: "",
        suicide: "",
        provideName: "",
        provideOldman: "",
      },
      rules: {
        evaluatecause: [
          {
            required: true,
            message: "评估原因不能为空",
            trigger: "change",
          },
        ],
        nation: [
          {
            required: true,
            message: "民族不能为空",
            trigger: "change",
          },
        ],
        education: [
          {
            required: true,
            message: "文化程度不能为空",
            trigger: "change",
          },
        ],
        marriage: [
          {
            required: true,
            message: "婚姻状况不能为空",
            trigger: "change",
          },
        ],
        live: [
          {
            required: true,
            message: "居住状况不能为空",
            trigger: "change",
          },
        ],
        moneySource: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个经济来源",
            trigger: "change",
          },
        ],
        dementia: [
          {
            required: true,
            message: "疾病诊断-痴呆不能为空",
            trigger: "change",
          },
        ],
        spirit: [
          {
            required: true,
            message: "疾病诊断-精神疾病不能为空",
            trigger: "change",
          },
        ],
        chronic: [
          {
            required: true,
            message: "请输入疾病诊断-慢性疾病",
            trigger: "blur",
          },
        ],
        fall: [
          {
            required: true,
            message: "近30天内意外事件-跌倒不能为空",
            trigger: "change",
          },
        ],
        lost: [
          {
            required: true,
            message: "近30天内意外事件-走失不能为空",
            trigger: "change",
          },
        ],
        blocking: [
          {
            required: true,
            message: "近30天内意外事件-噎食不能为空",
            trigger: "change",
          },
        ],
        suicide: [
          {
            required: true,
            message: "近30天内意外事件-自杀不能为空",
            trigger: "change",
          },
        ],
        provideName: [
          {
            required: true,
            message: "请输入信息提供者的姓名",
            trigger: "blur",
          },
        ],
        other: [
          {
            required: true,
            message: "近30天内意外事件-其他不能为空",
            trigger: "blur",
          },
        ],
        provideOldman: [
          {
            required: true,
            message: "信息提供者与老人的关系不能为空",
            trigger: "change",
          },
        ],
      },
      // 评估原因
      evaluatecauseOptions: "",
      nationOptions: "",
      // 文化程度
      educationOptions: "",
      marriageOptions: "",
      liveOptions: "",
      moneySourceOptions: "",
      dementiaOptions: "",
      spiritOptions: "",
      fallOptions: "",
      lostOptions: "",
      blockingOptions: "",
      suicideOptions: "",
      provideOldmanOptions: "",
      // 评估编号
      number: "",
      evaluationDate: "",
      userInfo: "",
      oldid: this.elderlyid,
    };
  },
  computed: {},
  watch: {},
  created() {
    // 给页面数据赋值
    this.evaluatecauseOptions = evaluatecauseOptions();
    this.nationOptions = nationOptions();
    this.educationOptions = educationOptions();
    this.marriageOptions = marriageOptions();
    this.liveOptions = liveOptions();
    this.moneySourceOptions = moneySourceOptions();
    this.dementiaOptions = dementiaOptions();
    this.spiritOptions = spiritOptions();
    this.fallOptions = fallOptions();
    this.lostOptions = lostOptions();
    this.blockingOptions = blockingOptions();
    this.suicideOptions = suicideOptions();
    this.provideOldmanOptions = provideOldmanOptions();
    // 计算评估编号和时间
    var date = new Date();
    var year = date.getFullYear();
    var milliseconds = date.getMilliseconds();
    var month = date.getMonth();
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    month < 9 ? (month = "0" + (month + 1)) : (month = month + 1);
    day < 9 ? (day = "0" + day) : day;
    hours < 9 ? (hours = "0" + hours) : hours;
    minutes < 9 ? (minutes = "0" + minutes) : minutes;
    seconds < 9 ? (seconds = "0" + seconds) : seconds;
    milliseconds < 9 ? (milliseconds = "00" + milliseconds) : milliseconds;
    milliseconds < 100 ? (milliseconds = "0" + milliseconds) : milliseconds;
    var number =
      String(year) +
      String(month) +
      String(day) +
      String(hours) +
      String(minutes) +
      String(seconds) +
      String(milliseconds);
    var evaluationDate = String(year + "-" + month + "-" + day);
    this.number = number;
    this.evaluationDate = evaluationDate;

    // 获取评估人员的信息
    let info = localStorage.getItem("userinfo");
    this.userInfo = JSON.parse(info);

    if (this.oldid) {
      this.get_detail();
    }
  },
  mounted() {},
  methods: {
    //上一步
    lastStep() {
      this.$emit("lastStep", 0);
    },
    // 下一步
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          // TODO 提交表单
          let data = new FormData();
          data.append("number", this.number);
          data.append("evaluationDate", this.evaluationDate);
          data.append("evaluatecause", this.formData.evaluatecause);
          data.append("education", this.formData.education);
          data.append("nation", this.formData.nation);
          data.append("marriage", this.formData.marriage);
          data.append("live", this.formData.live);
          data.append("moneySource", this.formData.moneySource.toString());
          data.append("dementia", this.formData.dementia);
          data.append("spirit", this.formData.spirit);
          data.append("chronic", this.formData.chronic);
          data.append("fall", this.formData.fall);
          data.append("lost", this.formData.lost);
          data.append("blocking", this.formData.blocking);
          data.append("suicide", this.formData.suicide);
          data.append("id", this.oldid);
          data.append("provideOldman", this.formData.provideOldman);
          data.append("provideName", this.formData.provideName);
          data.append("other", this.formData.other);
          saveBasicInfo(data)
            .then((result) => {
              this.$emit("changefromloist", 1, "数组改变了");
              this.$message({
                message: result.msg,
                type: "success",
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    // 老人信息详情
    get_detail() {
      let data = new FormData();
      data.append("id", this.oldid);
      detail(data)
        .then((result) => {
          if (result.data.evaluationDate) {
            this.formData = result.data;
            let arr = result.data.moneySource.split(",");
            for (let i in arr) {
              arr[i] = arr[i] * 1;
            }
            this.formData.moneySource = arr;

            this.formData.provideOldman = Number(result.data.provideOldman);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style  scoped>
div /deep/.el-form-item__content {
  text-align: left;
}
div /deep/.el-form-item {
  width: 80vw;
  margin: 0 auto;
}
div /deep/.el-form-item--large .el-form-item__content {
  text-align: center;
}
</style>
<style lang="less" scoped>
.box {
  box-sizing: border-box;
  padding: 30px 100px;
}
h2 {
  text-align: left;
  padding-left: 5vw;
}
.title {
  background: #3276ff;
  color: #fff;
  height: 8vh;
  line-height: 8vh;
  margin-bottom: 2vh;
}
.userinfo {
  display: flex;
  padding: 1vw 0;
}
.label {
  width: 150px;
  text-align: left;
  padding: 0 14px;
  font-size: 14px;
  color: #606266;
}
</style>
