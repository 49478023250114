
<template>
  <!-- 添加老人评估 -->
  <div class="box">
    <el-row :gutter="15">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <el-col :span="24">
          <h2>添加老人能力评估</h2>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="所在区域" prop="areaId">
            <treeselect
              v-model="formData.areaId"
              @select="selectArea"
              :options="areaOptions"
              :disabled="areashow"
              :show-count="true"
              placeholder="请选择所在区域"
            >
              <div slot="value-label" slot-scope="{ node }">
                {{ node.raw.text }}
              </div>
            </treeselect>
          </el-form-item>
        </el-col> -->

        <el-col :span="12">
          <el-form-item label="老人姓名" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入老人姓名"
              clearable
              :disabled="isdisabled"
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="身份证号" prop="card">
            <el-input
              v-model="formData.card"
              placeholder="请输入身份证号"
              clearable
              :style="{ width: '100%' }"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="blur"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出生日期" prop="birthDate">
            <el-input
              v-model="formData.birthDate"
              placeholder="系统生成"
              clearable
              disabled
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="gender">
            <el-select
              v-model="formData.gender"
              placeholder="系统生成"
              disabled
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="(item, index) in genderOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系方式" prop="phone2">
            <el-input
              v-model="formData.phone2"
              placeholder="请输入联系方式"
              :disabled="isdisabled"
              clearable
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="固话" prop="phone1">
            <el-input
              v-model="formData.phone1"
              placeholder="请输入固话"
              :disabled="isdisabled"
              clearable
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="所在区域" prop="areaName">
            <AreaFormCascader
              v-if="isshow"
              ref="AreaFormCascader"
              @getAreaFrom="selectArea($event)"
              :placeholder="'请选择所在区域'"
            ></AreaFormCascader>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址" prop="fulladdress">
            <el-input
              v-model="formData.fulladdress"
              placeholder="请输入详细地址"
              :disabled="isdisabled"
              clearable
              :style="{ width: '100%' }"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item size="large">
            <el-button
              type="primary"
              @click="submitForm"
              v-if="disabled == true"
              >下一步</el-button
            >
            <el-button type="primary" v-if="disabled == false"
              >下一步</el-button
            >
            <el-button @click="resetForm">取消</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import {
  saveBasicInfo,
  detail,
  getBasicInfo,
  treeList,
} from "@/api/login/login";
export default {
  components: {},
  props: {
    elderlyid: {
      type: [String, Number],
    },
  },
  data() {
    return {
      prop: {
        label: "label",
        value: "id",
        checkStrictly: true,
        // lazyLoad: (node, resolve) => {
        //   if(node.level==0)
        //   const { value, level } = node;
        //   let data = {
        //     parentId: node.value,
        //   };
        //   treeselect(data).then((res) => {
        //     if (level == 4) {
        //       resolve(
        //         res.data.map((item) => {
        //           item.leaf = "leaf"; //这个必须加，就是让组件识别是不是最后一级
        //           return item;
        //         })
        //       );
        //       this.loading = false;
        //     } else {
        //       resolve(res.data);
        //       this.loading = false;
        //     }
        //   });
        // },
      },
      isshow: false,
      isdisabled: false,
      // 区划树选项
      areaOptions: [],
      disabled: true,
      //  默认 可以编辑
      areashow: false,
      formData: {
        name: "",
        card: "",
        birthDate: "",
        gender: "",
        phone2: "",
        phone1: "",
        areaName: "",
        fulladdress: "",
      },
      genderOptions: [
        {
          label: "男",
          value: 0,
        },
        {
          label: "女",
          value: 1,
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入老人姓名",
            trigger: "blur",
          },
        ],
        card: [
          {
            required: true,
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证",
            trigger: "blur",
          },
        ],
        birthDate: [],
        gender: [],
        areaName: [
          {
            required: true,
            message: "区划不能为空",
            trigger: "blur",
          },
        ],
        phone2: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
            required: true,
          },
        ],
        fulladdress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
      },
      oldid: "",
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children:
            node.children && node.children.length > 0 ? node.children : 0,
        };
      },
      provinceId: "",
      cityId: "",
      areaId: "",
      streetId: "",
      communityId: "",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    if (this.elderlyid) {
      this.oldid = this.elderlyid;
    }
    if (this.oldid) {
      this.get_detail();
      // this.isdisabled=false;
    } else {
      this.isshow = true;
    }
  },
  methods: {
    selectArea(e) {
      console.log("e", e);
      this.formData.provinceId = e.areaIdArr[0];
      this.formData.cityId = e.areaIdArr[1];
      this.formData.areaId = e.areaIdArr[2];
      this.formData.streetId = e.areaIdArr[3];
      this.formData.communityId = e.areaIdArr[4];
      console.log(this.provinceId);
      this.formData.areaName = e.areaNameArr.join(",");
      console.log(this.areaName);
    },

    // 区划转化数组
    areaIdArrFormat(row) {
      var arr = [];
      if (row.areaName) {
        if (row.provinceId) {
          arr.push(row.provinceId);
        }
        if (row.cityId) {
          arr.push(row.cityId);
        }
        if (row.areaId) {
          arr.push(row.areaId);
        }
        if (row.streetId) {
          arr.push(row.streetId);
        }
        if (row.communityId) {
          arr.push(row.communityId);
        }
        return arr;
      }
    },
    // 出生日期
    GetBirthday(psidno) {
      var birthdayno, birthdaytemp;
      if (psidno.length == 18) {
        birthdayno = psidno.substring(6, 14);
      } else if (psidno.length == 15) {
        birthdaytemp = psidno.substring(6, 12);
        birthdayno = "19" + birthdaytemp;
      } else {
        return "";
      }
      var birthday =
        birthdayno.substring(0, 4) +
        "-" +
        birthdayno.substring(4, 6) +
        "-" +
        birthdayno.substring(6, 8);
      return birthday;
    },
    //性别
    Getsex(psidno) {
      var sexno, sex;
      if (psidno.length == 18) {
        sexno = psidno.substring(16, 17);
      } else if (psidno.length == 15) {
        sexno = psidno.substring(14, 15);
      } else {
        return "";
      }
      var tempid = sexno % 2;
      if (tempid == 0) {
        sex = 1;
      } else {
        sex = 0;
      }
      return sex;
    },

    blur() {
      let birthDate = this.GetBirthday(this.formData.card);
      this.formData.birthDate = birthDate;
      let gender = this.Getsex(this.formData.card);
      console.log('gender',gender)
      this.formData.gender = gender;
      let data = new FormData();
      data.append("idcardno", this.formData.card);
      data.append("userId", localStorage.getItem("userId"));
      this.isshow = false;
      getBasicInfo(data)
        .then((result) => {
          if (result.data) {
            if (result.data.status == 2) {
              this.$confirm("该老人已评估，是否需要重新评估？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
                this.formData = result.data;
                this.formData.gender = Number(result.data.gender);
                this.isshow = true;
                // this.oldid = result.data.id;
                this.$nextTick(() => {
                  this.$refs.AreaFormCascader.form.areaIdArr =
                    this.areaIdArrFormat(result.data);
                });
              });
            } else if (result.data.status == 1) {
              // this.disabled = false;
              this.isshow = true;
               this.$nextTick(() => {
                  this.$refs.AreaFormCascader.form.areaIdArr =
                    this.areaIdArrFormat(result.data);
                });
              this.$message({
                message: "该老人信息已存在，请到列表继续评估该老人",
              });
            }
          } else {
            this.isshow = true;
            this.isdisabled = false;
            // this.$refs["elForm"].resetFields();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 提交
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (valid) {
          let data = new FormData();
          data.append("name", this.formData.name);
          data.append("card", this.formData.card);
          data.append("birthDate", this.formData.birthDate);
          data.append("gender", this.formData.gender);
          data.append("phone2", this.formData.phone2);
          data.append("phone1", this.formData.phone1);
          data.append("areaName", this.formData.areaName);
          data.append("provinceId", this.formData.provinceId);
          data.append("cityId", this.formData.cityId);
          data.append("areaId", this.formData.areaId);
          data.append("streetId", this.formData.streetId);
          data.append("communityId", this.formData.communityId);
          data.append("fulladdress", this.formData.fulladdress);
          data.append("id", this.oldid);
          console.log("data", data);
          saveBasicInfo(data)
            .then((result) => {
              this.$emit("changeData", 1, "数组改变了");
              localStorage.setItem("userinfo", JSON.stringify(this.formData));

              this.$emit("oldid", result.data.id);
              this.$message({
                message: result.msg,
                type: "success",
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    // 老人信息详情
    get_detail() {
      this.isshow = false;
      let data = new FormData();
      data.append("id", this.oldid);
      detail(data)
        .then((result) => {
          this.formData = result.data;
          this.formData.gender = Number(result.data.gender);
          this.isshow = true;
          this.$nextTick(() => {
            this.$refs.AreaFormCascader.form.areaIdArr = this.areaIdArrFormat(
              result.data
            );
          });

          console.log(this.areaIdArrFormat(result.data));
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>
<style scoped>
div /deep/.el-input-group__append {
  background: #7e8ef3;
  color: #fff;
}
div /deep/.el-input__inner {
  height: auto !important;
}
.box {
  padding: 30px 100px;
}
h2 {
  text-align: left;
  padding-left: 1vw;
}
/* div /deep/.el-form-item__label{
  font-size: 18px;
} */
</style>
